<template>
    <div>
        <modal-box :is-active="isModalActive" :trash-object-name="trashObjectName" @confirm="trashConfirm"
                   @cancel="trashCancel"/>
        <b-table
                :checkable="checkable"
                :loading="isLoading"
                :paginated="paginated"
                :per-page="perPage"
                :striped="true"
                :hoverable="true"
                default-sort="name"
                :data='bambini'>

          <b-table-column v-slot="props" label="Cognome" field="surname">
            {{ props.row.cognome }}
          </b-table-column>
          <b-table-column v-slot="props" label="Nome" field="name">
            {{ props.row.nome }}
          </b-table-column>
          <b-table-column v-slot="props" label="Data di nascita" field="birthdate">
            {{ props.row.datanascita | moment('DD/MM/YYYY') }}
          </b-table-column>

          <b-table-column v-slot="props" label="Codice Fiscale" field="birthdate">
            {{ props.row.codicefiscale }}
          </b-table-column>


          <b-table-column v-slot="props" custom-key="actions" class="is-actions-cell">
            <div class="buttons is-right">
              <router-link :to="getRichiestaUrl(props.row.idpaziente)" class="button is-primary" type="button">
                <b-icon icon="plus" custom-size="default"/>
                <span>Nuova richiesta</span>
              </router-link>
            </div>
          </b-table-column>

          <section class="section" slot="empty">
                <div class="content has-text-grey has-text-centered">
                    <template v-if="isLoading">
                        <p>
                            <b-icon icon="dots-horizontal" size="is-large"/>
                        </p>
                        <p>Sto caricando...</p>
                    </template>
                    <template v-else>
                        <p>
                            <b-icon icon="emoticon-sad" size="is-large"/>
                        </p>
                        <p>Nessun bambino</p>
                    </template>
                </div>
            </section>
        </b-table>
    </div>
</template>

<script>
  import axios from 'axios'
  import ModalBox from '@/components/ModalBox'

  export default {
    name: 'BambiniTable',
    components: { ModalBox },
    props: {
      dataUrl: {
        type: String,
        default: null
      },
      checkable: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isModalActive: false,
        trashObject: null,
        bambini: [],
        isLoading: false,
        paginated: false,
        perPage: 10
      }
    },
    computed: {
      trashObjectName () {
        if (this.trashObject) {
          return this.trashObject.name
        }

        return null
      }
    },
    mounted () {
      if (this.dataUrl) {
        this.isLoading = true
        axios
          .get(this.dataUrl)
          .then(r => {
            this.isLoading = false
            if (r.data && r.data.data) {
              if (r.data.data.length > this.perPage) {
                this.paginated = true
              }
              this.bambini = r.data.data
            }
          })
          .catch(e => {
            this.isLoading = false
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger'
            })
          })
      }
    },
    methods: {
      trashModal (trashObject) {
        this.trashObject = trashObject
        this.isModalActive = true
      },
      trashConfirm () {
        this.isModalActive = false
        this.$buefy.snackbar.open({
          message: 'Confirmed',
          queue: false
        })
      },
      trashCancel () {
        this.isModalActive = false
      },
      getRichiestaUrl(idPaziente)
      {
        return '/richiesta-presa-in-carico/invia?idp=' + btoa(idPaziente)
      }
    }
  }
</script>
