<template>
  <section class="section">
    <title-bar>
      Dashboard
    </title-bar>

    <h2 class="subtitle"><strong>I tuoi profili</strong></h2>
    <card-component class="has-table" v-if="bambiniUrl">
      <bambini-table :data-url="bambiniUrl" :checkable="false"/>
    </card-component>
    <br />
    <h2 class="subtitle"><strong>Le tue richieste di presa in carico</strong></h2>
    <card-component class="has-table" v-if="richiestePresaUrl">
      <richieste-presa-in-carico-table :data-url="richiestePresaUrl" :checkable="false"/>
    </card-component>

  </section>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import * as chartConfig from '@/components/Charts/chart.config'
import CardComponent from '@/components/CardComponent'
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import LineChart from '@/components/Charts/LineChart'
import ClientsTableSample from '@/components/ClientsTableSample'
import TitleBar from '@/components/TitleBar'
import RichiestePresaInCaricoTable from "../components/RichiestePresaInCaricoTable";
import BambiniTable from "../components/BambiniTable";

export default {
  name: 'home',
  components: {
    BambiniTable,
    RichiestePresaInCaricoTable,
    TitleBar,
    ClientsTableSample,
    CardWidget,
    Tiles,
    CardComponent,
    LineChart
  },
  data () {
    return {
      richiestePresaUrl: null,
      bambiniUrl: null,
    }
  },
  computed: {

  },
  methods: {

  },
  mounted () {
    this.richiestePresaUrl = this.api.base + "/prese-in-carico?user_id=" + this.user.id
    this.bambiniUrl = this.api.base + "/pazienti?user_id=" + this.user.id
  },
}
</script>
