<template>
  <div class="diagnosi form__section">
    <b-field horizontal label="Diagnosi del vostro bambino">
      <BInputValidate rules="required" type="text" v-model="diagnosi"/>
    </b-field>

    <div class="multi-items__row terapie__row">
      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Terapie farmacologiche attuali</label>
          <checkbox-picker class="multi-items__checkbox" :options="{disabled:'Nessuna terapia'}" v-model="terapie.disabled" type="is-primary"/>
        </div>

        <div class="field-body field-body--wrap">
          <template v-if="!terapie.disabled" v-for="(terapia, index) in terapie.items">
            <b-input v-model="terapie.items[index].value" class="is-fullwidth is-block" v-if="index" />
            <b-input-validate v-model="terapie.items[index].value" rules="required" class="is-fullwidth is-block" v-else />
          </template>
        </div>
      </div>
    </div>

    <b-field horizontal label="Epilessia">
      <b-radio-validate :options="{si:'Sì',no:'No'}" rules="required" v-model="epilessia"></b-radio-validate>
    </b-field>

    <b-field class="field--2080" horizontal label="Allergie ed intolleranze">
      <b-radio-validate :options="{si:'Sì',no:'No'}" rules="required" v-model="allergie.yesno"></b-radio-validate>
      <b-input-validate v-if="allergie.yesno === 'si'" rules="required" v-model="allergie.value" placeholder="Specificare allergie e intolleranze" />
    </b-field>


    <div class="multi-items__row ausilii__row">
      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Eventuali Ausilii</label>
          <checkbox-picker class="multi-items__checkbox" :options="{disabled:'Nessun ausilio'}" v-model="ausilii.disabled" type="is-primary"/>
        </div>

        <div class="field-body field-body--wrap">
          <div class="columns" v-if="!ausilii.disabled">
            <div class="column is-one-third">
              <b-input-validate label="Visivi" v-model="ausilii.visivi" :rules="getAusiliiVisiviRules()" />
            </div>
            <div class="column is-one-third">
              <b-input-validate label="Motori" v-model="ausilii.motori" :rules="getAusiliiMotoriRules()" />
            </div>
            <div class="column is-one-third">
              <b-input-validate label="Acustici" v-model="ausilii.acustici" :rules="getAusiliiAcusticiRules()" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import BInputValidate from "../../components/inputs/BInputValidate";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import BRadioValidate from "../../components/inputs/BRadioValidate";
  import BSelectValidate from "../../components/inputs/BSelectValidate";
  import CheckboxPicker from "../../components/CheckboxPicker";
  import BCheckboxesValidate from "../../components/inputs/BCheckboxesValidate";

  export default {
    name: 'Diagnosi',
    props: {
      readonly: {
        type: Boolean,
        default: false
      }
    },
    components: {
      BSelectValidate,
      BRadioValidate,
      ValidationObserver,
      ValidationProvider,
      BInputValidate,
      CheckboxPicker,
      BCheckboxesValidate
    },
    data() {
      return {
        isLoading: false,
        diagnosi: '',
        terapie: {
          disabled: false,
          startWith: 4,
          items: []
        },
        epilessia: null,
        allergie: {
          yesno: null,
          value: ''
        },
        ausilii: {
          disabled: false,
          visivi: '',
          motori: '',
          acustici: ''
        }
      }
    },
    methods: {
      getData(){
          return {
            Diagnosi_del_bambino: this.diagnosi,
            Epilessia: this.epilessia,
            Allergie_si_no: this.allergie.yesno,
            Allergie_tipo: this.allergie.value,
            Farmaco_attuale_1: this.terapie.disabled ? '' : this.terapie.items[0].value,
            Farmaco_attuale_2: this.terapie.disabled ? '' : this.terapie.items[1].value,
            Farmaco_attuale_3: this.terapie.disabled ? '' : this.terapie.items[2].value,
            Farmaco_attuale_4: this.terapie.disabled ? '' : this.terapie.items[3].value,
            Ausilii_visivi: this.ausilii.visivi,
            Ausilii_motori: this.ausilii.motori,
            Ausilii_acustici: this.ausilii.acustici
          }
      },
      getAusiliiVisiviRules(){
          if(this.ausilii.motori.length || this.ausilii.acustici.length){
            return '';
          }
          return 'required';
      },

      getAusiliiMotoriRules(){
        if(this.ausilii.visivi.length || this.ausilii.acustici.length){
          return '';
        }
        return 'required';
      },


      getAusiliiAcusticiRules(){
        if(this.ausilii.motori.length || this.ausilii.visivi.length){
          return '';
        }
        return 'required';
      },

      setData(data)
      {
        this.diagnosi = data.diagnosi;

        if(data.terapie.items){
          this.terapie.items = data.terapie.items
          if(this.terapie.items.length < this.terapie.startWith){
            for(let i = this.terapie.items.length; i < this.terapie.startWith; i++){
                this.terapie.items.push({value: ''})
              }
          }
        }

        this.epilessia = data.epilessia;

        this.ausilii = {
          ...this.ausilii,
          ...data.ausilii
        };

        this.allergie = {
          ...this.allergie,
          ...data.allergie
        };

      }
    },
    mounted(){
      for(let i = 0; i < this.terapie.startWith; i++){
        this.terapie.items.push({value: ''})
      }
    }
  }
</script>

<style lang="scss">
  .is-fullwidth{
    width: 100%;
  }
  .terapie__row{
    margin: 30px 0;
    .field-body{
      .is-fullwidth.is-block{
        margin-bottom: 5px;
      }
    }
  }
  .ausilii__row{
    margin: 30px 0;
  }

  .field--2080 > .field-body > .field:first-child{
    max-width: 150px;
  }
</style>
