export default {
    request: function (req, token) {
        this.options.http._setHeaders.call(this, req, { Authorization: 'Bearer ' + token });
    },

    response: function (res) {
        var token = res.data.access_token;

        if (token) {
            token = token.split(/Bearer\:?\s?/i);

            return token[token.length > 1 ? 1 : 0].trim();
        }
    }
};