import axios from 'axios'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    token: null,
    remember: null,
  },

  getters: {
    authenticated(state) {
      return state.authenticated
    },

    user(state) {
      return state.user
    },

    token(state) {
      return state.token
    },

    remember(state) {
      return state.remember
    },

  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },

    SET_USER(state, value) {
      state.user = value
    },

    SET_TOKEN(state, value) {
      state.token = value
    },

    SET_REMEMBER(state, value) {
      state.remember = value
    },
  },

  actions: {
    async signIn({dispatch}, loginData) {
      return await axios.post(window.apiUrls.base + '/auth/login', loginData)
        .then(async (response) => {

          await dispatch('setAuthenticated', response.data.data.authed)

          if (response.data.data.user) {
            await dispatch('setUser', response.data.data.user)
          }

          if (response.data.data.authed) {
            return true
            //return await dispatch('me')
          }
          return false
          //await dispatch('setRemember', loginData.remember)
        })
        .catch(() => {
          return null
        })
    },

    async autoLogin({dispatch}, token) {
      return axios.post(window.apiUrls.base + '/auth/autologin', {token: token})
        .then(async (r) => {
          return await dispatch('me')
        })
        .catch(() => {
          return null
        })
    },

    async signOut({dispatch}) {
      await axios.post(window.apiUrls.base + '/auth/logout')
      await dispatch('setToken', null)
      return dispatch('me', {})
    },

    setToken({commit}, value) {
      commit('SET_TOKEN', value)
    },

    setRemember({commit}, value) {
      commit('SET_REMEMBER', value)
    },

    setUserId({commit}, value) {
      commit('SET_USER', {id: value})
    },

    setAuthenticated({commit}, value){
      commit("SET_AUTHENTICATED", value)
    },

    setUser({commit}, value) {
      commit('SET_USER', value)
    },

    me({commit}) {
      return axios.get(window.apiUrls.base + '/me', {
        withCredentials: true
      }).then((response) => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data.data.user)
        return response.data.data.user
      }).catch(() => {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
        return null
      })
    }
  },
}