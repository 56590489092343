 <template>
  <div class="scuola form__section">

    <checkbox-picker :options="{disabled:'Non frequenta la scuola'}"
                     v-model="disabled" type="is-primary"/>

    <template v-if="!disabled">
      <b-field horizontal label="Scuola">
        <b-radio-validate :disabled="readonly" :options="getRadioFriendlyOptions(scuola.options)" rules="required"
                          v-model="scuola.value"></b-radio-validate>
      </b-field>

      <b-field horizontal label="Classe frequentata">
        <b-radio-validate :disabled="readonly" :options="getRadioFriendlyOptions(classe.options)" rules="required"
                          v-model="classe.value"></b-radio-validate>
      </b-field>

      <b-field horizontal label="Nome della scuola">
        <BInputValidate :disabled="readonly" rules="required" type="text" v-model="nome"/>
      </b-field>

      <b-field horizontal label="Indirizzo della scuola">
        <BInputValidate :disabled="readonly" rules="required" type="text" v-model="indirizzo"/>
      </b-field>

      <b-field :key="index" horizontal :label="item.title" v-for="(item, index) in personale">
        <b-radio-validate @input="changePersonaleItem(item, index)" :disabled="readonly" :options="{si:'Sì',no:'No'}" rules="required" v-model="item.enabled"></b-radio-validate>
        <BInputValidate :disabled="readonly" :label="'Nome ' + item.title" v-if="item.enabled === 'si'" rules="required" type="text"
                        :id="item.slug + '_nome'"
                        v-model="item.nome"/>
      </b-field>

      <div class="files__wrap verbali-certificazione-scolastica">
        <strong>Verbali di certificazione scolastica</strong><br><br>
        <div :key="index" class="verbale" v-for="(item, index) in verbali">
          <div class="columns">
            <div class="column is-one-fifth">
              <b-input-validate label="Data" v-model="item.data" rules="required|date" placeholder="GG/MM/AAAA"
                                v-cleave="cleaveMasks.date"></b-input-validate>
            </div>
            <div class="column is-one-fifth">
              <b-input-validate label="Luogo" v-model="item.luogo" rules="required"></b-input-validate>
            </div>
            <div class="column is-one-fifth">
              <b-input-validate label="Ente" v-model="item.ente" rules="required"></b-input-validate>
            </div>
            <div class="column is-one-fifth">
              <ValidationProvider name="Verbale di certificazione scolastica" rules="filerequired" v-slot="{ errors, valid }">
                <file-picker v-model="item.file" text="Carica un file" label="Documento"></file-picker>
                <p class="help is-danger" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="column is-one-fifth">
              <label class="label" style="visibility: hidden">.</label>
              <b-button @click="removeVerbale(index)" v-if="item.custom" type="is-danger"
                        icon-left="delete">Rimuovi
              </b-button>
            </div>
          </div>
        </div>

        <b-field class="verbali__cta" label="">
          <div class="has-text-right">
            <b-button
              @click="addVerbale"
              type="is-info"
              icon-left="plus">
              Aggiungi documento
            </b-button>
          </div>
        </b-field>
      </div>

    </template>

    <hr>
  </div>
</template>

<script>
  import BInputValidate from "../../components/inputs/BInputValidate";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import BRadioValidate from "../../components/inputs/BRadioValidate";
  import BSelectValidate from "../../components/inputs/BSelectValidate";
  import CheckboxPicker from "../../components/CheckboxPicker";
  import FilePickerDragAndDrop from "../../components/FilePickerDragAndDrop";
  import FilePicker from "../../components/FilePicker";
  import Cleave from "cleave.js";


  const cleave = {
    name: 'cleave',
    bind(el, binding) {
      const input = el.querySelector('input')
      input._vCleave = new Cleave(input, binding.value)
    },
    unbind(el) {
      const input = el.querySelector('input')
      input._vCleave.destroy()
    }
  };

  export default {
    name: 'Scuola',
    directives: {
      cleave
    },
    props: {
      readonly: {
        type: Boolean,
        default: false
      }
    },
    components: {
      FilePicker,
      BSelectValidate,
      BRadioValidate,
      ValidationObserver,
      ValidationProvider,
      BInputValidate,
      CheckboxPicker,
      FilePickerDragAndDrop
    },
    data() {
      return {
        disabled: false,
        cleaveMasks: {
          date: {
            date: true,
            datePattern: ['d', 'm', 'Y']
          }
        },
        isLoading: false,
        scuola: {
          options: [
            {
              id: 1,
              title: 'Asilo nido',
              value: 'asilo-nido',
            },
            {
              id: 2,
              title: 'Scuola dell\'infanzia',
              value: 'scuola-infanzia',
            },
            {
              id: 3,
              title: 'Scuola primaria',
              value: 'scuola-primaria',
            },
            {
              id: 4,
              title: 'Scuola secondaria',
              value: 'scuola-secondaria',
            },
          ],
          value: null
        },
        classe: {
          options: [
            {
              id: 1,
              title: 'Prima',
              value: 1,
            },
            {
              id: 2,
              title: 'Seconda',
              value: 2,
            },
            {
              id: 3,
              title: 'Terza',
              value: 3,
            },
            {
              id: 4,
              title: 'Quarta',
              value: 4,
            },
            {
              id: 5,
              title: 'Quinta',
              value: 5,
            }
          ],
          value: null
        },
        nome: '',
        indirizzo: '',
        personale: [
          {
            id: 1,
            title: "Insegnante di sostegno",
            slug: 'insegnante-sostegno',
            enabled: '',
            nome: '',
          },
          {
            id: 2,
            title: "Operatore Socio Sanitario",
            slug: 'operatore-sanitario',
            enabled: '',
            nome: '',
          },
          {
            id: 3,
            title: "Personale ex Provincia",
            slug: 'personale-ex-provincia',
            enabled: '',
            nome: '',
          },
          {
            id: 4,
            title: "Personale del Comune",
            slug: 'personale-comune',
            enabled: '',
            nome: '',
          }
        ],
        verbali: [
          {
            data: '',
            luogo: '',
            ente: '',
            file: null,
            titolo: 'Verbale di certificazione scolastica'
          }
        ]
      }
    },
    methods: {
      changePersonaleItem(item, index){
        this.personale.splice(index, 1, item)
      },
      initVerbale(verbale) {
        verbale.data = '';
        verbale.ente = '';
        verbale.luogo = '';
        verbale.file = null;
        verbale.titolo = "Verbale di certificazione scolastica"
        return verbale;
      },
      addVerbale() {
        let verbale = {custom: true};
        this.initVerbale(verbale);
        this.verbali.push(verbale);
      },
      removeVerbale(indexToRemove) {
        this.verbali = this.verbali.filter((item, index) => index !== indexToRemove);
      },
      getRadioFriendlyOptions(options) {
        let newOptions = {};
        options.forEach((item, key) => {
          newOptions[item.title] = item.title;
        });
        return newOptions;
      },
      getData() {
        if(this.disabled){
          return {};
        }
        return {
          Scuola: this.scuola.value,
          Classe_frequentata: this.classe.value,
          Nome_della_scuola: this.nome,
          Indirizzo_della_scuola: this.indirizzo,
          Assistente_ULSS_si_no: this.personale[1].enabled,
          Assistente_ULSS_nome: this.personale[1].nome,
          Insegnante_di_sostegno_si_no: this.personale[0].enabled,
          Insegnante_di_sostegno_nome: this.personale[0].nome,
          Personale_della_Provincia_si_no: this.personale[2].enabled,
          Personale_della_Provincia_nome: this.personale[2].nome,
          Personale_del_Comune_si_no: this.personale[3].enabled,
          Personale_del_Comune_nome: this.personale[3].nome,
        };
      },
      setData(data)
      {
        if(data.scuola.value){
          const foundScuolaIndex = this.scuola.options.findIndex(item => {
            return item.value === data.scuola.value;
          });
          if(foundScuolaIndex > -1){
            this.scuola.value = this.scuola.options[foundScuolaIndex].title;
          }
        }
        if(data.classe.value){
          const foundClasseIndex = this.classe.options.findIndex(item => {
            return item.value === data.classe.value;
          });
          if(foundClasseIndex > -1){
            this.classe.value = this.classe.options[foundClasseIndex].title;
          }
        }
        this.nome = data.nome;
        this.indirizzo = data.indirizzo;
        data.personale.forEach( dataItem => {
          const foundPersonaleIndex = this.personale.findIndex(item => {
            return item.slug === dataItem.slug;
          });
          if(foundPersonaleIndex > -1){
            this.personale[foundPersonaleIndex] = {
              ...this.personale[foundPersonaleIndex],
              ...dataItem
            };
          }
        });
        this.$forceUpdate();
      },
      getFiles(){
        if(this.disabled){
          return [];
        }
        return this.verbali.map(item => {
          return {
            path: item.file,
            ente: item.ente,
            luogo: item.luogo,
            data: item.data,
            titolo: item.titolo
          }
        });
      }
    },
    mounted() {

    }
  }
</script>

<style scoped lang="scss">
  .is-fullwidth {
    width: 100%;
  }

  .verbali__cta {
    margin-top: 15px;
  }
</style>
