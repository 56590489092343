<template>
    <div class="richiesta-main">
        <router-view></router-view>
    </div>
</template>

<script>

  export default {
    name: 'RichiestaPresaInCaricoMain',
    components: {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    mounted() {

    }
  }
</script>
<style lang="scss">
</style>
