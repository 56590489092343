<template>

    <section class="section">
        <div class="logo" style="margin-bottom: 30px;max-width: 250px;">
            <img src="../../assets/hollman-logo.png" alt="Logo Fondazione Hollman"/>
        </div>
        <title-bar>
            Richiesta di Presa in Carico
        </title-bar>
        <h2 class="subtitle">Anno scolastico 2024/25</h2>
        <b-message type="is-success" v-if="success">
            La vostra richiesta di presa in carico è stata inviata correttamente, vi contatteremo a breve.
        </b-message>
        <form v-else @submit.prevent="submit">

            <b-tabs size="is-medium" type="is-boxed" class="block" v-model="activeTab">
                <b-tab-item label="Form" icon="google-photos">

                    <ValidationObserver ref="formObserver" v-slot="{ passes }">
                        <card-component title="Anagrafica" icon="ballot">
                            <anagrafica ref="anagrafica" :readonly="readonly" v-model="form.anagrafica"/>
                        </card-component>

                        <card-component title="Contatti" icon="ballot">
                            <contatti ref="contatti" :readonly="readonly" v-model="form.contatti"/>
                        </card-component>

                        <card-component title="Referenti" icon="ballot">
                            <referenti ref="referenti" :readonly="readonly" v-model="form.referenti"/>
                        </card-component>

                        <card-component title="Diagnosi" icon="ballot">
                            <diagnosi ref="diagnosi" :readonly="readonly" v-model="form.diagnosi"/>
                        </card-component>

                        <card-component title="Trattamenti" icon="ballot">
                            <trattamenti ref="trattamenti" :readonly="readonly" v-model="form.trattamenti"/>
                        </card-component>

                        <card-component title="Previdenze" icon="ballot">
                            <previdenze ref="previdenze" :readonly="readonly" v-model="form.previdenze"/>
                        </card-component>

                        <card-component title="Scuola" icon="ballot">
                            <scuola ref="scuola" :readonly="readonly" v-model="form.scuola"/>
                        </card-component>

                        <card-component title="Note" icon="ballot-outline">
                            <b-field horizontal
                                     label="Quali sono le richieste che rivolgete alla Fondazione Robert Hollman?">
                                <b-input :disabled="readonly" v-model="form.richieste" type="textarea"></b-input>
                            </b-field>

                            <b-field horizontal label="Data di compilazione">
                                <BInputValidate :disabled="readonly" v-model="form.dataCompilazione" rules="required" type="text"
                                                placeholder="GG/MM/AAAA" v-cleave="cleaveMasks.date"/>
                            </b-field>


                        </card-component>
                        <div class="has-text-right">
                            <b-button @click="nextStep" id="salva-procedi" type="is-primary">Salva e procedi</b-button>
                        </div>
                    </ValidationObserver>
                </b-tab-item>
                <b-tab-item label="Privacy" icon="library-music">
                    <ValidationObserver tag="div" class="privacy__observer" ref="privacyObserver" v-slot="{ passes }">
                        <card-component title="Privacy" icon="ballot-outline">
                            <privacy v-model="privacy" ref="privacy"></privacy>
                            <br>
                            <div class="has-text-right">
                                <b-button @click="submit" id="invia-richiesta" type="is-primary is-large" :class="{'is-loading':isLoading}">Invia richiesta</b-button>
                            </div>
                        </card-component>
                    </ValidationObserver>
                </b-tab-item>
            </b-tabs>


        </form>


    </section>
</template>

<script>
  import TitleBar from '@/components/TitleBar'
  import CardComponent from '@/components/CardComponent'
  import mapValues from 'lodash/mapValues'
  import CheckboxPicker from '@/components/CheckboxPicker'
  import RadioPicker from '@/components/RadioPicker'
  import FilePicker from '@/components/FilePicker'
  import FilePickerDragAndDrop from '@/components/FilePickerDragAndDrop'
  import BInputValidate from "@/components/inputs/BInputValidate";
  import { ValidationObserver } from "vee-validate";
  import BRadioValidate from "@/components/inputs/BRadioValidate";
  import Cleave from 'cleave.js'
  import BCheckboxesValidate from "@/components/inputs/BCheckboxesValidate";
  import BSelectValidate from "@/components/inputs/BSelectValidate";
  import Contatti from "./Contatti";
  import Referenti from "./Referenti";
  import Anagrafica from "./Anagrafica";
  import Diagnosi from "./Diagnosi";
  import Trattamenti from "./Trattamenti";
  import Previdenze from "./Previdenze";
  import Scuola from "./Scuola";
  import Privacy from "./Privacy";
  import moment from "moment";

  const cleave = {
    name: 'cleave',
    bind(el, binding) {
      const input = el.querySelector('input')
      input._vCleave = new Cleave(input, binding.value)
    },
    unbind(el) {
      const input = el.querySelector('input')
      input._vCleave.destroy()
    }
  };


  export default {
    name: 'RichiestaPresaInCarico',
    directives: {cleave},
    props: {
      idRichiesta: {
        type: Number,
        default: null
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    components: {
      Anagrafica,
      BSelectValidate,
      BCheckboxesValidate,
      BRadioValidate,
      ValidationObserver,
      BInputValidate,
      FilePickerDragAndDrop,
      FilePicker,
      RadioPicker,
      CheckboxPicker,
      CardComponent,
      TitleBar,
      Anagrafica,
      Contatti,
      Referenti,
      Diagnosi,
      Trattamenti,
      Previdenze,
      Scuola,
      Privacy,

      VueGoogleAutocomplete: () => {
        return new Promise((resolve, reject) => {
          let script = document.createElement('script');
          script.onload = () => {
            resolve(import('vue-google-autocomplete'));
          };
          script.async = true;
          script.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCmO0ACHjrJ_48uZw_uLQ5W1rsgbPsRS_E&libraries=places&language=it');
          document.head.appendChild(script);
        })
      }

    },
    data() {
      return {
        isLoading: false,
        success: null,
        privacy: {},
        activeTab: 0,
        form: {
          anagrafica: {
            domicilioUgualeResidenza: true
          },
          contatti: [],
          referenti: [],
          diagnosi: {},
          previdenze: {},
          scuola: {},
          richieste: '',
          dataCompilazione: ''
        },
        cleaveMasks: {
          date: {
            date: true,
            datePattern: ['d', 'm', 'Y']
          }
        },
        richiestaPresaInCarico: {},
      }
    },
    methods: {
      showValidationAlert() {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Si prega di compilare tutti i campi obbligatori",
          position: 'is-bottom',
          type: 'is-danger'
        })
      },
      scrollToFirstValidationError() {
        const firstErrorDiv = document.querySelector('.help.is-danger');
        if (!firstErrorDiv) {
          return;
        }
        this.$scrollTo(firstErrorDiv, 500, {offset: -100});
      },
      getAllData() {
        const anagrafica = this.$refs.anagrafica.getData();
        let presaInCaricoData = {
            ...anagrafica,
            Richieste_fondazione: this.form.richieste,
            Data_di_compilazione: this.form.dataCompilazione,
            Sede: 'Padova',
            inviato: 1
          },
          formData = {};

        if (this.richiestaPresaInCarico) {
          presaInCaricoData.idrichiesta = this.richiestaPresaInCarico.idrichiesta;
        }


        const previdenze = this.$refs.previdenze.getData();
        presaInCaricoData = {...presaInCaricoData, ...previdenze};
        const previdenzeFiles = this.$refs.previdenze.getFiles();
        formData.files = previdenzeFiles;

        const diagnosi = this.$refs.diagnosi.getData();
        presaInCaricoData = {...presaInCaricoData, ...diagnosi};

        const scuola = this.$refs.scuola.getData();
        presaInCaricoData = {...presaInCaricoData, ...scuola};
        const scuolaFiles = this.$refs.scuola.getFiles();
        formData.files = formData.files.concat(scuolaFiles);

        const contatti = this.$refs.contatti.getData();
        formData.contatti = contatti;

        const trattamenti = this.$refs.trattamenti.getData();
        formData.terapie = trattamenti;

        const referenti = this.$refs.referenti.getData();
        formData.professionisti = referenti;
        if(this.paziente.id){
          presaInCaricoData.idpaziente = this.paziente.id
        }
        if(this.user.id){
          presaInCaricoData.user_id = this.user.id
        }
        formData.presaInCarico = presaInCaricoData;
        if(this.inviteLog.id){
          formData.invite_log_id = this.inviteLog.id
        }
        return formData;
      },
      async nextStep() {
        const formData = this.getAllData();
        const validates = await this.$refs.formObserver.validate();
        if (!validates) {
          this.showValidationAlert();
          this.scrollToFirstValidationError();
          return false;
        }
        this.$refs.privacy.setNomeBambino(formData.presaInCarico.Nome + ' ' + formData.presaInCarico.Cognome);
        this.activeTab = 1;
        window.scrollTo(0,0);
      },

      async submit() {
        if (this.isLoading) {
          return;
        }
        let formData = this.getAllData();
        const privacyValidates = await this.$refs.privacyObserver.validate();
        if (!privacyValidates) {
          this.showValidationAlert();
          this.scrollToFirstValidationError();
          return false;
        }

        const privacy = this.$refs.privacy.getData();
        formData.presaInCarico = {...formData.presaInCarico, ...privacy};

        const privacyFiles = this.$refs.privacy.getFiles();
        formData.files = formData.files.concat(privacyFiles).map(item => {
          return {
            path: item.path,
            ente: item.ente,
            luogo: item.luogo,
            data: this.$moment(item.data, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            titolo: item.titolo
          }
        });

        this.isLoading = true;
        const result = await this.apiSubmit(formData);
        if (result.presaInCarico) {
          this.richiestaPresaInCarico = result.presaInCarico;
        }
        this.isLoading = false;
      },
      apiSubmit(postData) {
        return this.$http.post(process.env.VUE_APP_API_URL + "/prese-in-carico", postData)
          .then(r => {
            console.log(r);
            if (r.data.success) {
              this.success = true;
              return r.data.data;
            }
            return {};
          })
      },
      reset() {
        this.form = mapValues(this.form, item => {
          if (item && typeof item === 'object') {
            return []
          }
          return null
        })

        this.$buefy.snackbar.open({
          message: 'Reset successfully',
          queue: false
        })
      },
      async prefillData(){
        const prefillDataResult = await this.apiGetPrefillData(this.paziente.id);

        if(prefillDataResult){
          this.$refs.anagrafica.setData(prefillDataResult.anagrafica);
          this.$refs.contatti.setData(prefillDataResult.contatti);
          this.$refs.referenti.setData(prefillDataResult.referenti);
          this.$refs.diagnosi.setData(prefillDataResult.diagnosi);
          this.$refs.trattamenti.setData(prefillDataResult.trattamenti);
          this.$refs.previdenze.setData(prefillDataResult.previdenze);
          this.$refs.scuola.setData(prefillDataResult.scuola);
          this.$refs.privacy.setData(this.user.name, this.user.surname);

          this.form.richieste = prefillDataResult.richieste;
        }
      },
      apiGetPrefillData(idPaziente)
      {
        return this.$http.get(process.env.VUE_APP_API_URL + "/pazienti/"+idPaziente + "/prefill")
          .then(r => {
            if (r.data.success) {
              return r.data.data;
            }
            return null;
          })
      },
    },
    mounted() {

      this.form.dataCompilazione = this.$moment().format('DD/MM/YYYY');

      if(!this.inviteLog.id && !this.paziente.id){
        this.decodeQueryData()
      }

      if(!this.paziente.id){
        console.log('error')
        return
      }

      this.prefillData()
    }
  }
</script>
<style lang="scss">
    .files__wrap {
        background: #f8f8f8;
        padding: 15px;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 30px;
        border-radius: 4px;
    }

    .privacy__observer {
        max-width: 900px;
    }

    .field.is-grouped.is-grouped-multiline:not(:last-child) + .help.is-danger {
        margin-top: -0.75em;
        margin-bottom: 1em;
    }
</style>
