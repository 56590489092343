<template>
  <aside
      v-show="isAsideVisible"
      class="aside is-placed-left"
      :class="{'is-expanded':isAsideExpanded}">
    <div ref="container" class="aside-container" @mouseenter="psUpdate">
      <div class="image" :class="{'fadeIn animated':hasFadeIn}">
        <img src="../assets/hollman-logo.png" />
      </div>
      <div  class="menu is-menu-main fast" :class="{'fadeIn animated':hasFadeIn}" @animationend="hasFadeIn = false">
        <aside-menu-list
            @menu-click="menuClick"
            :menu="menu"/>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex'
import PerfectScrollbar from 'perfect-scrollbar'

export default {
  name: 'AsideMenu',
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      hasFadeOut: false,
      hasFadeIn: false
    }
  },
  computed: {
    hasAnimated () {
      return this.hasFadeOut || this.hasFadeIn
    },
    ...mapState([
      'isAsideVisible',
      'isAsideExpanded',
      'isAsideMobileExpanded'
    ])
  },
  mounted () {
    this.ps = new PerfectScrollbar(this.$refs.container)
  },
  methods: {
    menuClick (item) {
      if (this.ps && item && item.menu) {
        this.ps.update()
      }
    },
    psUpdate () {
      if (this.ps) {
        this.ps.update()
      }
    }
  },
  watch: {
    isAsideExpanded () {
      this.hasFadeIn = true
    }
  }
}
</script>
