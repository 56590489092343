import { required, confirmed, length, email, numeric, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import moment from "moment";

extend("numeric", {
  ...numeric,
  message: "Questo campo deve essere numerico"
});

extend("required", {
  ...required,
  message: "Questo campo è obbligatorio"
});

extend("email", {
  ...email,
  message: "Questo campo deve essere un indirizzo email valido"
});

extend("confirmed", {
  ...confirmed,
  message: "Le due password non coincidono"
});

extend("length", {
  ...length,
  message: "This field must have 2 options"
});

extend('min', {
  ...min,
  message: 'Questo campo deve avere almeno {length} caratteri'
})

extend('min_numeric', {
  ...min,
  message: 'Il numero deve avere almeno {length} cifre'
})

extend('date', {
  message: field => 'Questa data non ha un formato valido. Formato accettato: GG/MM/AAAA. Esempio: 14/01/2015',
  validate: value => { return moment(value, 'DD/MM/YYYY', true).isValid() }
});

extend("filerequired", {
  ...required,
  message: "Si prega di caricare un documento"
});


extend("addressrequired", {
  ...required,
  message: "Si prega di indicare un indirizzo",
  validate: value => {
    return typeof value.civico !== 'undefined' && value.civico.length;
  }
});


extend('consensoEsprime', {
  message: field => 'Questo consenso è obbligatorio',
  validate: value => {
    return value === 'esprime'
  }
});

extend("required_if_empty", {
  validate: (value, { target }) => {
    if(['', null, undefined].indexOf(target) > -1) {
      return {
        required: true,
        valid: ['', null, undefined].indexOf(value) === -1
      };
    }
    return {
      required: false,
      valid: true
    };
  },
  message: "Almeno un campo tra telefono ed email deve essere compilato",
  params: ['target'],
  computesRequired: true,
});
