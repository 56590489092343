<template>
    <section class="section">
        <title-bar>
            Richieste di Presa in Carico
            <router-link to="/richiesta-presa-in-carico/invia" class="button is-primary" slot="button">
                Aggiungi richiesta
            </router-link>
        </title-bar>
        <card-component class="has-table has-mobile-sort-spaced">
            <div>
                <modal-box :is-active="isModalActive" :trash-object-name="trashObjectName" @confirm="trashConfirm"
                           @cancel="trashCancel"/>
                <b-table
                        :checkable="checkable"
                        :loading="isLoading"
                        :paginated="paginated"
                        :per-page="perPage"
                        :striped="true"
                        :hoverable="true"
                        default-sort="name"
                        :data='richieste'>

                    <template slot-scope="props">
                        <b-table-column label="Nome" field="name">
                            {{ props.row.Cognome }} {{ props.row.Nome }}
                        </b-table-column>

                        <b-table-column label="Data di nascita" field="birthday">
                            {{ props.row.Data_di_nascita }}
                        </b-table-column>

                        <b-table-column label="Residenza">
                            {{ props.row.Comune_di_residenza }}
                            ({{ props.row.Provincia_di_residenza }})
                        </b-table-column>
                        <b-table-column label="Data compilazione">
                            {{ props.row.Data_di_compilazione }}
                        </b-table-column>

                        <b-table-column custom-key="actions" class="is-actions-cell">
                            <div class="buttons is-right">
                                <router-link :to="'/richiesta-presa-in-carico/'+props.row.idrichiesta" class="button is-primary">
                                    <b-icon icon="eye" custom-size="default"/>
                                    <span>Visualizza</span>
                                </router-link>
                                <button class="button is-small is-danger" type="button" @click.prevent="trashModal(props.row)">
                                    <b-icon icon="trash-can" custom-size="default"/>
                                    <span>Elimina</span>
                                </button>
                            </div>
                        </b-table-column>
                    </template>

                    <section class="section" slot="empty">
                        <div class="content has-text-grey has-text-centered">
                            <template v-if="isLoading">
                                <p>
                                    <b-icon icon="dots-horizontal" size="is-large"/>
                                </p>
                                <p>Fetching data...</p>
                            </template>
                            <template v-else>
                                <p>
                                    <b-icon icon="emoticon-sad" size="is-large"/>
                                </p>
                                <p>Nothing's there&hellip;</p>
                            </template>
                        </div>
                    </section>
                </b-table>
            </div>
        </card-component>
    </section>
</template>

<script>
  import axios from 'axios'
  import ModalBox from '@/components/ModalBox'
  import CardComponent from '@/components/CardComponent'
  import TitleBar from "../../components/TitleBar";

  export default {
    name: 'RichiestaPresaInCaricoList',
    components: { ModalBox, CardComponent, TitleBar },
    props: {
      checkable: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isModalActive: false,
        trashObject: null,
        richieste: [],
        isLoading: false,
        paginated: false,
        perPage: 25,
        dataUrl: process.env.VUE_APP_API_URL + "/prese-in-carico"
      }
    },
    computed: {
      trashObjectName () {
        if (this.trashObject) {
          return this.trashObject.name
        }

        return null
      }
    },
    mounted () {
    this.isLoading = true;
    this.$http
      .get(this.dataUrl)
      .then(r => {
        this.isLoading = false
        if (r.data) {
          if (r.data.length > this.perPage) {
            this.paginated = true
          }
          this.richieste = r.data
        }
      })
      .catch(e => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })

    },
    methods: {
      trashModal (trashObject) {
        this.trashObject = trashObject
        this.isModalActive = true
      },
      trashConfirm () {
        this.isModalActive = false
        this.$buefy.snackbar.open({
          message: 'Confirmed',
          queue: false
        })
      },
      trashCancel () {
        this.isModalActive = false
      }
    }
  }
</script>
