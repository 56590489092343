<template>
  <div class="anagrafica">
    <b-field horizontal label="Nome">
      <BInputValidate rules="required" type="text" placeholder="Nome" v-model="anagrafica.nome"/>
    </b-field>
    <b-field horizontal label="Cognome">
      <BInputValidate rules="required" type="text" placeholder="Cognome" v-model="anagrafica.cognome"/>
    </b-field>
    <b-field horizontal label="Sesso">
      <b-radio-validate v-model="anagrafica.sesso" rules="required"
                        :options="{M:'Maschio',F:'Femmina', A:'Non dichiarato'}"/>
    </b-field>

    <b-field horizontal label="Data di nascita">
      <BInputValidate v-model="anagrafica.dataNascita" rules="required" type="text" placeholder="GG/MM/AAAA"
                      v-cleave="cleaveMasks.date"/>
    </b-field>

    <div class="field is-horizontal field--address">
      <div class="field-label">
        <label for="residenza" class="label">Residenza</label>
      </div>
      <div class="field-body" style="flex-direction: column;">
        <div>
          <p class="help" style="font-size: 14px; margin-bottom: 5px;">Cerca l'indirizzo e seleziona quello corretto</p>
          <vue-google-autocomplete
                  id="residenza"
                  ref="residenza"
                  classname="input"
                  name="residenza"
                  placeholder="Indirizzo..."
                  v-model="anagrafica.residenza.query"
                  v-on:placechanged="updateResidenza"
                  v-on:inputChange="updateResidenzaValidation"
          >
          </vue-google-autocomplete>
          <p class="help" v-if="!anagrafica.residenza.manual">
            <a href="javascript:void(0)" @click="toggleResidenzaManually">Non trovi l'indirizzo? Clicca qui per inserirlo manualmente</a>
          </p>
          <validation-provider ref="residenzaprovider" class="residenza__validation" name="Indirizzo di residenza" rules="required" v-slot="{ errors, valid }">
            <input style="display:none" name="residenzacivico" v-model="anagrafica.residenza.indirizzo.via" />
            <p class="help is-danger" v-if="errors.length">{{ errors[0] }}</p>
          </validation-provider>
        </div>
        <div class="control" v-if="anagrafica.residenza.manual">
          <br>
          <b-message type="is-warning">
            Vi preghiamo di controllare che il vostro indirizzo precompilato qui sotto sia corretto
          </b-message>
          <b-field class="horizontal--left-align" horizontal label="Via">
              <BInputValidate rules="required" type="text" v-model="anagrafica.residenza.indirizzo.via" />
          </b-field>
            <b-field class="horizontal--left-align" horizontal label="Civico">
              <BInputValidate rules="required" type="text" v-model="anagrafica.residenza.indirizzo.civico" />
          </b-field>
          <b-field class="horizontal--left-align"  horizontal label="CAP">
            <BInputValidate rules="required" type="text" v-model="anagrafica.residenza.indirizzo.cap" />
          </b-field>

          <b-field class="horizontal--left-align" horizontal label="Comune">
            <BInputValidate rules="required" type="text" v-model="anagrafica.residenza.indirizzo.comune" />
          </b-field>

          <b-field class="horizontal--left-align" horizontal label="Provincia">
            <BInputValidate rules="required" type="text" v-model="anagrafica.residenza.indirizzo.provincia" />
          </b-field>

          <b-field class="horizontal--left-align" horizontal label="Regione">
            <BInputValidate rules="required" type="text" v-model="anagrafica.residenza.indirizzo.regione" />
          </b-field>

          <b-field class="horizontal--left-align" horizontal label="Nazione">
            <BInputValidate rules="required" type="text" v-model="anagrafica.residenza.indirizzo.nazione" />
          </b-field>

          <p class="help" v-if="false">
            <a href="javascript:void(0)" @click="toggleResidenzaManually">Cerca l'indirizzo</a>
          </p>
        </div>
      </div>

    </div>


    <b-field horizontal label="Domicilio uguale a residenza?">
      <b-checkboxes-validate>
        <b-switch v-model="anagrafica.domicilioUgualeResidenza">
          <span v-if="anagrafica.domicilioUgualeResidenza">Sì</span>
          <span v-else>No</span>
        </b-switch>
      </b-checkboxes-validate>
    </b-field>


    <div class="field is-horizontal field--address" v-if="!anagrafica.domicilioUgualeResidenza">
      <div class="field-label">
        <label for="domicilio" class="label">Domicilio</label>
      </div>
      <div class="field-body" style="flex-direction: column;">
        <div>
          <p class="help" style="font-size: 14px; margin-bottom: 5px;">Cerca l'indirizzo e seleziona quello corretto</p>
          <vue-google-autocomplete
                  id="domicilio"
                  ref="domicilio"
                  classname="input"
                  v-model="anagrafica.domicilio.query"
                  :disabled="anagrafica.domicilioUgualeResidenza"
                  placeholder="Indirizzo"
                  v-on:placechanged="updateDomicilio"
          >
          </vue-google-autocomplete>
          <p class="help">
            <a href="javascript:void(0)" @click="toggleDomicilioManually">Non trovi l'indirizzo? Clicca qui per inserirlo manualmente</a>
          </p>
        </div>

        <div class="control" v-if="anagrafica.domicilio.manual">
          <br>
          <b-message type="is-warning">
            Vi preghiamo di controllare che il vostro indirizzo precompilato qui sotto sia corretto
          </b-message>
          <b-field class="horizontal--left-align" horizontal label="Via">
            <BInputValidate rules="required" type="text" v-model="anagrafica.domicilio.indirizzo.via" />
          </b-field>
          <b-field class="horizontal--left-align" horizontal label="Civico">
            <BInputValidate rules="required" type="text" v-model="anagrafica.domicilio.indirizzo.civico" />
          </b-field>
          <b-field class="horizontal--left-align"  horizontal label="CAP">
            <BInputValidate rules="required" type="text" v-model="anagrafica.domicilio.indirizzo.cap" />
          </b-field>

          <b-field class="horizontal--left-align" horizontal label="Comune">
            <BInputValidate rules="required" type="text" v-model="anagrafica.domicilio.indirizzo.comune" />
          </b-field>

          <b-field class="horizontal--left-align" horizontal label="Provincia">
            <BInputValidate rules="required" type="text" v-model="anagrafica.domicilio.indirizzo.provincia" />
          </b-field>

          <b-field class="horizontal--left-align" horizontal label="Regione">
            <BInputValidate rules="required" type="text" v-model="anagrafica.domicilio.indirizzo.regione" />
          </b-field>

          <b-field class="horizontal--left-align" horizontal label="Nazione">
            <BInputValidate rules="required" type="text" v-model="anagrafica.domicilio.indirizzo.nazione" />
          </b-field>

          <p class="help" v-if="false">
            <a href="javascript:void(0)" @click="toggleResidenzaManually">Cerca l'indirizzo</a>
          </p>
        </div>

      </div>
    </div>
  </div>
</template>
<style lang="scss">
    .field--civico{
      @media screen and (min-width:768px){
        margin-left: 15px;
        max-width: 200px;
      }
    }
    .horizontal--left-align{
      .field-label{
        text-align: left!important;
      }
    }
</style>
<script>
  import CheckboxPicker from '@/components/CheckboxPicker'
  import RadioPicker from '@/components/RadioPicker'
  import BInputValidate from "@/components/inputs/BInputValidate";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import BRadioValidate from "@/components/inputs/BRadioValidate";
  import Cleave from 'cleave.js'
  import BCheckboxesValidate from "@/components/inputs/BCheckboxesValidate";
  import BSelectValidate from "@/components/inputs/BSelectValidate";

  const cleave = {
    name: 'cleave',
    bind(el, binding) {
      const input = el.querySelector('input')
      input._vCleave = new Cleave(input, binding.value)
    },
    unbind(el) {
      const input = el.querySelector('input')
      input._vCleave.destroy()
    }
  };


  export default {
    name: 'Anagrafica',
    directives: {cleave},
    props: {
      readonly: {
        type: Boolean,
        default: false
      }
    },
    components: {
      BSelectValidate,
      BCheckboxesValidate,
      BRadioValidate,
      ValidationObserver,
      ValidationProvider,
      BInputValidate,
      RadioPicker,
      CheckboxPicker,

      VueGoogleAutocomplete: () => {
        return new Promise((resolve, reject) => {
          let script = document.createElement('script');
          script.onload = () => {
            resolve(import('vue-google-autocomplete'));
          };
          script.async = true;
          script.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCmO0ACHjrJ_48uZw_uLQ5W1rsgbPsRS_E&libraries=places&language=it');
          document.head.appendChild(script);
        })
      }

    },
    data() {
      return {
        isLoading: false,
        anagrafica: {
          nome: '',
          cognome: '',
          sesso: '',
          dataNascita: '',
          domicilioUgualeResidenza: true,
          residenza: {
            query: '',
            manual:false,
            indirizzo: {}
          },
          domicilio: {
            query: '',
            manual:false,
            indirizzo: {}
          }
        },
        cleaveMasks: {
          date: {
            date: true,
            datePattern: ['d', 'm', 'Y']
          }
        }
      }
    },
    methods: {
      toggleResidenzaManually()
      {
          this.anagrafica.residenza.manual = !this.anagrafica.residenza.manual;
      },
      toggleDomicilioManually()
      {
        this.anagrafica.domicilio.manual = !this.anagrafica.domicilio.manual;
      },
      updateResidenzaValidation()
      {
          if(this.$refs.residenzaprovider.errors.length){
              this.$refs.residenzaprovider.validate();
          }
      },
      updateResidenza(googleResponse, googlePlace, elementId) {
        this.setIndirizzoFromGoogleResponse(this.anagrafica.residenza.indirizzo, googleResponse, googlePlace);
        this.toggleResidenzaManually();
      },
      updateDomicilio(googleResponse, googlePlace, elementId) {
        this.setIndirizzoFromGoogleResponse(this.anagrafica.domicilio.indirizzo, googleResponse, googlePlace);
        this.toggleDomicilioManually();
      },
      isIndirizzoValid(indirizzo)
      {
        if(typeof indirizzo.via === 'undefined' || !indirizzo.via.length){
          return false;
        }
        if(typeof indirizzo.civico === 'undefined' || !indirizzo.civico.length){
          return false;
        }
        if(typeof indirizzo.cap === 'undefined' || !indirizzo.cap.length){
          return false;
        }
        if(typeof indirizzo.comune === 'undefined' || !indirizzo.comune.length){
          return false;
        }
        if(typeof indirizzo.provincia === 'undefined' || !indirizzo.provincia.length){
          return false;
        }
        if(typeof indirizzo.nazione === 'undefined' || !indirizzo.nazione.length){
          return false;
        }
        return true;
      },
      initIndirizzo(indirizzo) {
        indirizzo.via = "";
        indirizzo.civico = "";
        indirizzo.cap = "";
        indirizzo.comune = "";
        indirizzo.provincia = "";
        indirizzo.regione = "";
        indirizzo.nazione = "";
        indirizzo.coordinate = {
          latitudine: '',
          longitudine: ''
        }
      },
      setIndirizzoFromGoogleResponse(indirizzo, googleResponse, googlePlace) {
        indirizzo.regione = googleResponse.administrative_area_level_1;
        indirizzo.nazione = googleResponse.country;
        indirizzo.coordinate.latitudine = googleResponse.latitude;
        indirizzo.coordinate.longitudine = googleResponse.longitude;
        indirizzo.comune = googleResponse.locality;
        indirizzo.cap = googleResponse.postal_code;
        indirizzo.via = googleResponse.route;
        indirizzo.civico = googleResponse.street_number;
        indirizzo.provincia = this.getGooglePlaceAddressComponentByType(googlePlace.address_components, 'administrative_area_level_2');
      },
      getGooglePlaceAddressComponentByType(addressComponent, type) {
        let results = addressComponent.filter(item => {
          return item.types.includes(type);
        });
        if (!results.length) {
          return '';
        }
        return results.shift().short_name;
      },
      getData() {
        const residenza = this.anagrafica.residenza.indirizzo;
        let data = {
          Cognome: this.anagrafica.cognome,
          Nome: this.anagrafica.nome,
          Sesso: this.anagrafica.sesso,
          Data_di_nascita: this.anagrafica.dataNascita,
          Via_di_residenza: residenza.via,
          Civico_di_residenza: residenza.civico,
          Cap_di_residenza: residenza.cap,
          Comune_di_residenza: residenza.comune,
          Provincia_di_residenza: residenza.provincia
        };
        if (this.anagrafica.domicilioUgualeResidenza) {
          return data;
        }
        const domicilio = this.anagrafica.domicilio.indirizzo;
        data.Via_del_domicilio = domicilio.via;
        data.Civico_del_domicilio = domicilio.civico;
        data.Cap_del_domicilio = domicilio.cap;
        data.Comune_del_domicilio = domicilio.comune;
        data.Provincia_del_domicilio = domicilio.provincia;
        return data;
      },
      setPazienteData(paziente)
      {
        this.anagrafica.nome = paziente.nome;
        this.anagrafica.cognome = paziente.cognome;
        this.anagrafica.sesso = paziente.sesso;
        this.anagrafica.domicilioUgualeResidenza = !!paziente.domiciliougualeresidenza;
        this.anagrafica.dataNascita = this.$moment(paziente.datanascita).format('DD/MM/YYYY');
      },
      setData(data)
      {
        this.anagrafica = {
          ...this.anagrafica,
          ...data
        };
      }
    },
    mounted() {
      this.initIndirizzo(this.anagrafica.residenza.indirizzo);
      this.initIndirizzo(this.anagrafica.domicilio.indirizzo);
    }
  }
</script>
