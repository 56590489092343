<template>
  <div class="referenti multi-items form__section">

    <div class="multi-items__row referenti__row is-flex" v-for="(referente, index) in referenti">
      <referente :referente="referente" :index="index" @remove="removeReferente" @update="update" />
    </div>

    <b-field class="referenti__cta" label="">
      <div style="text-align:right">
        <b-button
          @click="addReferente"
          type="is-info"
          icon-left="plus">
          Aggiungi
        </b-button>
      </div>
    </b-field>
  </div>
</template>

<script>
  import BInputValidate from "../../components/inputs/BInputValidate";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import BRadioValidate from "../../components/inputs/BRadioValidate";
  import BSelectValidate from "../../components/inputs/BSelectValidate";
  import BCheckboxesValidate from "../../components/inputs/BCheckboxesValidate";
  import CheckboxPicker from "../../components/CheckboxPicker";
  import Referente from "./Referente";

  export default {
    name: 'Referenti',
    props: {
      readonly: {
        type: Boolean,
        default: false
      }
    },
    components: {
      Referente,
      BCheckboxesValidate,
      BSelectValidate,
      BRadioValidate,
      ValidationObserver,
      ValidationProvider,
      BInputValidate,
      CheckboxPicker
    },
    data() {
      return {
        isLoading: false,
        referenti: [
          {
            title: "Pediatra / medico di base",
            slug: 'pediatra',
            disabled: false,
            custom: false,
            nome: '',
            cognome: '',
            ente: '',
            telefono: '',
            email: '',
            disabledText: 'Non ho un pediatra'
          },
          {
            title: "Oculista",
            slug: 'oculista',
            disabled: false,
            custom: false,
            nome: '',
            cognome: '',
            ente: '',
            telefono: '',
            email: '',
            disabledText: 'Non ho un oculista'
          },
          {
            title: "Neuropsichiatra infantile",
            slug: 'npi',
            disabled: false,
            custom: false,
            nome: '',
            cognome: '',
            ente: '',
            telefono: '',
            email: '',
            disabledText: 'Non ho un neuropsichiatra'
          },
          {
            title: "Fisiatra",
            slug: 'fisiatra',
            disabled: false,
            custom: false,
            nome: '',
            cognome: '',
            ente: '',
            telefono: '',
            email: '',
            disabledText: 'Non ho un fisiatra'
          }
        ]
      }
    },
    methods: {
      getData(){
          return this.referenti.filter(item => !item.disabled)
            .map( item => {
              return {
                tipo: item.title,
                nome: item.nome,
                cognome: item.cognome,
                ente: item.ente,
                telefono: item.telefono,
                email: item.email
              }
          });
      },
      initReferente(referente) {
        referente.title = '';
        referente.nome = '';
        referente.cognome = '';
        referente.ente = '';
        referente.telefono = '';
        referente.email = '';
        return referente;
      },
      addReferente() {
        let referente = {custom: true};
        this.initReferente(referente);
        this.referenti.push(referente);
      },
      removeReferente(indexToRemove) {
        this.referenti = this.referenti.filter((item, index) => index !== indexToRemove);
      },
      getTelefonoRules(referente){
          if(referente.email.length){
              return 'numeric';
          }
          return 'requiredreferente|numeric';
      },
      getEmailRules(referente){
        if(referente.telefono.length){
          return 'email';
        }
        return 'requiredreferente|email';
      },
      update()
      {
        this.$forceUpdate();
      },
      setData(data)
      {
        data.forEach( dataItem => {
          const foundReferenteIndex = this.referenti.findIndex(item => {
            return (item.title === dataItem.title || item.slug === dataItem.title.toLowerCase());
          });
          if(foundReferenteIndex > -1){
            this.referenti[foundReferenteIndex] = {
              ...this.referenti[foundReferenteIndex],
              ...dataItem
            };
            return true;
          }

          let referente = {
            custom: true,
            ...dataItem
          };
          this.referenti.push(referente);
        });
        this.$forceUpdate();
      }
    }
  }
</script>

<style scoped lang="scss">
  .referenti__row + .referenti__row, .referenti__cta{
    margin-top: 30px;
  }
  .referenti__row > .field > .field-label {
    flex-grow: 2;
    text-align: left;
  }
</style>
