<template>
  <div id="app">
    <nav-bar/>
    <aside-menu :menu="menu"/>
    <router-view>
      <router-view></router-view>
    </router-view>
    <aside-right/>
    <footer-bar/>
  </div>
</template>

<style lang="scss">

</style>
<script>
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import AsideRight from '@/components/AsideRight'

export default {
  components: { AsideRight, FooterBar, AsideMenu, NavBar },
  computed: {
    menu () {
      return [
        {
          to: '/',
          icon: 'desktop-mac',
          label: 'Dashboard'
        },
        {
          to: '/tables',
          label: 'Tables',
          icon: 'table',
          updateMark: true
        },
        {
          to: '/forms',
          label: 'Forms',
          icon: 'square-edit-outline'
        },
        {
          to: '/profile',
          label: 'Profile',
          icon: 'account-circle'
        },
        {
          to: '/login',
          label: 'Login',
          icon: 'lock'
        },
        {
          to: '/error-in-card',
          label: 'Error v.1',
          icon: 'power-plug'
        },
        {
          to: '/error-simple',
          label: 'Error v.2',
          icon: 'alert-decagram'
        },
        {
          to: '/lock-screen',
          label: 'Lock Screen',
          icon: 'lock-reset'
        },
        {
          href: 'https://justboil.me/bulma-admin-template/two',
          label: 'Buy theme',
          icon: 'credit-card'
        },
        {
          label: 'Submenus',
          subLabel: 'Submenus Example',
          icon: 'view-list',
          menu: [
            {
              href: '#void',
              label: 'Sub-item One',
              icon: 'menu'
            },
            {
              href: '#void',
              label: 'Sub-item Two',
              icon: 'menu'
            }
          ]
        }
      ]
    }
  },
  created () {

  }
}
</script>
