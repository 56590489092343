<template>
  <b-field class="file" v-bind="$attrs">
    <b-upload v-model="file" @input="upload" :accept="accept">
      <a class="button is-primary">
        <b-icon icon="upload" custom-size="default"></b-icon>
        <span>{{ buttonLabel }}</span>
      </a>
    </b-upload>
    <p v-if="!success" class="help">Dimensione massima: 128MB</p>
    <b-message type="is-warning" size="is-small" v-if="isLoading">
      Sto caricando...
    </b-message>
    <b-message type="is-success" size="is-small" v-else-if="success">
      File "{{ file.name }}" caricato
    </b-message>
    <b-message type="is-danger" size="is-small" v-else-if="error">
      Errore nel caricamento del file "{{ file.name }}"
    </b-message>

  </b-field>
</template>

<script>

export default {
  name: 'FilePicker',
  props: {
    accept: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: 'Pick a file'
    }
  },
  data () {
    return {
      isLoading: false,
      success: false,
      error: false,
      file: null,
      uploadPercent: 0
    }
  },
  computed: {
    buttonLabel () {
      return !this.file ? this.text : 'Scegli un altro file'
    }
  },
  methods: {
    upload (file) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append('file', file);

      this.$http.post(window.apiBaseUrl + "/media-store", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: this.progressEvent
        })
        .then(r => {
            if(r.data.success){
              this.$emit("input", r.data.data.path);
              this.isLoading = false;
              this.success = true;
            }
        })
        .catch(err => {
          this.isLoading = false;
          this.error = true;
          this.$buefy.toast.open({
            message: 'Errore nel caricamento del file, si prega di riprovare',
            type: 'is-danger'
          })
        })
    },
    progressEvent (progressEvent) {
      this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    }
  }
}
</script>
