<template>
  <div class="contatti form__section">
    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label">Recapiti</label>
      </div>
      <div class="field-body field-body--wrap">
        <div class="contatti__row is-flex" v-for="(contatto, index) in contatti">
          <b-select-validate rules="required" name="Il tipo di contatto" placeholder="Scegli un tipo di contatto" v-model="contatto.type" >
            <option v-for="(contattoType, index) in contattoTypes" :key="index" :value="contattoType.id">
              {{ contattoType.title }}
            </option>
          </b-select-validate>
          <b-field v-if="getContattoTypeInputType(contatto.type) === 'tel'">
            <ValidationProvider :rules="getContattoRules(index, contatto.type)" v-slot="{ errors, valid }">

              <b-field
                :type="{ 'is-danger': errors[0]}"
                :message="errors"
              >
                <p class="control">
                  <a class="button is-static">
                    +39
                  </a>
                </p>
                <b-input type="tel" v-model="contatto.value" name="phone" expanded/>
              </b-field>

            </ValidationProvider>
          </b-field>
          <b-field v-else>
            <BInputValidate :rules="getContattoRules(index, contatto.type)" :type="getContattoTypeInputType(contatto.type)" v-model="contatto.value"/>
          </b-field>
          <b-button @click="removeContatto(index)" v-if="index" type="is-danger"
                    icon-left="delete">
            Rimuovi
          </b-button>
        </div>
      </div>
    </div>

    <b-field label="">
      <div style="text-align:right">
        <b-button
          @click="addContatto"
          type="is-info"
                  icon-left="plus">
          Aggiungi
        </b-button>
      </div>
    </b-field>
  </div>
</template>

<script>
  import BInputValidate from "../../components/inputs/BInputValidate";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import BRadioValidate from "../../components/inputs/BRadioValidate";
  import BSelectValidate from "../../components/inputs/BSelectValidate";

  export default {
    name: 'Contatti',
    props: {
      readonly: {
        type: Boolean,
        default: false
      }
    },
    components: {
      BSelectValidate,
      BRadioValidate,
      ValidationObserver,
      ValidationProvider,
      BInputValidate,
    },
    data() {
      return {
        isLoading: false,
        contatti: [
          {
            id: 0,
            type: 0,
            value: ''
          }
        ],
        contattoTypes: [
          {
            id: 0,
            title: 'Telefono di Casa',
            inputType: 'tel'
          },
          {
            id: 1,
            title: 'Telefono Madre',
            inputType: 'tel'
          },
          {
            id: 2,
            title: 'Telefono Padre',
            inputType: 'tel'
          },
          {
            id: 3,
            title: 'Telefono Nonni',
            inputType: 'tel'
          },
          {
            id: 4,
            title: 'E-mail',
            inputType: 'email'
          },
          {
            id: 5,
            title: 'Fax',
            inputType: 'tel'
          },
          {
            id: 6,
            title: 'Altro',
            inputType: 'text'
          },
        ],
      }
    },
    methods: {
      getData(){
          return this.contatti.map(item => {
            let contatto = {
              tipo: this.getContattoTypeById(item.type).title
            };
            if(this.contattoTypes[item.type].inputType === 'tel'){
              contatto.valore = "+39" + item.value;
              return contatto;
            }
            contatto.valore = item.value;
            return contatto;
          })
      },
      getContattoTypeById(id){
        return this.contattoTypes.find(item => {
          return item.id === id;
        });
      },
      getContattoTypeInputType(id){
        const result = this.getContattoTypeById(id);
        if(!result){
          return 'tel';
        }
        return result.inputType;
      },
      getContattoRules(index, id){
        const result = this.getContattoTypeById(id);
        if(!result){
          return 'required';
        }
        if(result.inputType === 'email'){
          return 'required|email';
        }
        if(result.inputType === 'tel'){
          return 'required|numeric|min_numeric:7';
        }
        return 'required';
      },
      removeContatto(indexToRemove) {
        this.contatti = this.contatti.filter((item, index) => index !== indexToRemove);
      },
      addContatto() {
        let newContatto = {
          id: this.contatti.length,
          type: 0,
          value: '',
        };
        this.contatti.push(newContatto);
      },
      setData(contatti)
      {
        if(contatti.length){
          this.contatti = [];
        }
        contatti.forEach( item => {
            this.contatti.push({
              id: item.idcontatto,
              type: parseInt(item.tipo),
              value: item.contatto
            });
        });
      }
    },
    mounted(){
      this.contatti[0].type = 1;
      this.contatti[0].type = 0;
    }
  }
</script>

<style scoped lang="scss">
  .contatti__row {
    width: 100%;
    justify-content: flex-start;
    & + .contatti__row{
      margin-top: 15px;
    }
    & > div, & > span{
      padding-right: 5px;
    }
    & > span {
      width: calc( 30% - 15px);
    }

    & > .field {
      width: calc( 50% - 15px);
    }


    & > .button {
      width: calc( 20% - 15px);
    }
  }
</style>
