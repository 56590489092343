<template>
    <div class="field is-horizontal">
        <div class="field-label" v-if="referente.custom">
            <label class="label">Altro</label>
            <BInputValidate rules="required" type="text" placeholder="Specificare professione" v-model="referente.title"/>
            <b-button @click="remove(index)" v-if="index" type="is-danger"
                      icon-left="delete">
                Rimuovi
            </b-button>
        </div>
        <div class="field-label" v-else>
            <label class="label">{{ referente.title }}</label>
            <checkbox-picker :options="{disabled:referente.disabledText}"
                             v-model="referente.disabled" type="is-primary"
                             @input="update"
            />
        </div>

        <div class="field-body field-body--wrap" v-if="!referente.disabled">
            <div class="columns">
                <div class="column is-half">
                    <BInputValidate rules="required" type="text" placeholder="Nome" v-model="referente.nome"/>
                </div>
                <div class="column is-half">
                    <BInputValidate rules="required" type="text" placeholder="Cognome" v-model="referente.cognome"/>
                </div>
            </div>
            <div class="columns">
                <div class="column is-half">
                    <BInputValidate rules="numeric" :name="'telefono_'+index" type="tel" placeholder="Telefono" v-model="referente.telefono"/>
                </div>
                <div class="column is-half">
                    <BInputValidate rules="email" :name="'email_'+index" type="email" placeholder="Email" v-model="referente.email"/>
                </div>
            </div>
            <br>
            <div class="columns" style="width: 100%;flex-shrink: 0;">
                <div class="column is-half">
                    <BInputValidate rules="required" type="text" placeholder="Ente" v-model="referente.ente"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import BInputValidate from "../../components/inputs/BInputValidate";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import CheckboxPicker from "../../components/CheckboxPicker";

  export default {
    name: 'Referente',
    components: {
      ValidationObserver,
      ValidationProvider,
      BInputValidate,
      CheckboxPicker
    },
    props: {
      referente: {
        default: () => {},
        type: Object
      },
      index: {
        default: null,
        type: Number
      }
    },
    methods: {
      remove(index){
        this.$emit('remove', index);
      },
      update(){
        this.$forceUpdate();
      }
    }
  }
</script>