/* Styles */
import '@/scss/main.scss'
import '@mdi/font/css/materialdesignicons.css'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'

window.apiUrls = {
  root: process.env.VUE_APP_API_ROOT,
  base: process.env.VUE_APP_API_URL
}

import store from './store'

/* Service Worker */
import './registerServiceWorker'

/* Vue. Main component */
import App from './App.vue'

/* Vue. Component in recursion */
import AsideMenuList from '@/components/AsideMenuList'

/* Validation */
import './vee-validate'


import auth from './auth/auth';

Vue.config.productionTip = false

/* These components are used in recursion algorithm */
Vue.component('AsideMenuList', AsideMenuList)

Vue.use(Buefy)

// Moment
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';

moment.locale('it');
Vue.use(VueMoment, {
  moment,
});

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

Bugsnag.start({
  apiKey: '479b186bd27ad2123e1f513cb37a27ec',
  plugins: [new BugsnagPluginVue(Vue)]
})

// Axios
import axios from 'axios';
import VueAxios from 'vue-axios';

axios.defaults.withCredentials = true;
window.axios = axios;
Vue.use(VueAxios, axios);


Vue.router = router;

import VueAuth from '@websanova/vue-auth';

axios.defaults.baseURL = `${process.env.VUE_APP_URL}/api`;
Vue.use(VueAuth, auth);

// ScrollTo
import VueScrollTo from 'vue-scrollto';
import { mapGetters } from "vuex";

Vue.use(VueScrollTo);

window.apiBaseUrl = process.env.VUE_APP_API_URL;


Vue.component(
  'passport-clients',
  require('./components/passport/Clients.vue').default
);

Vue.component(
  'passport-authorized-clients',
  require('./components/passport/AuthorizedClients.vue').default
);

Vue.component(
  'passport-personal-access-tokens',
  require('./components/passport/PersonalAccessTokens.vue').default
);

Vue.mixin({
  computed: {
    ...mapGetters({
      globalLoading: 'loading',
      user: 'auth/user',
      authenticated: 'auth/authenticated',
      presaInCarico: 'presaInCarico',
      paziente: 'paziente',
      inviteLog: 'inviteLog',
    }),
  },
  data: function () {
    return {
      queryData: {},
      api: {
        root: process.env.VUE_APP_API_ROOT,
        base: process.env.VUE_APP_API_URL
      },
    }
  },

  methods: {
    decodeQueryData() {
      if (this.$route.query.idi) {
        this.$store.commit('SET_INVITE_LOG',{id: atob(this.$route.query.idi)})
      }
      if (this.$route.query.idp) {
        this.$store.commit('SET_PAZIENTE',{id: atob(this.$route.query.idp)})
      }
    },
  }
})


axios.get(process.env.VUE_APP_API_ROOT + '/sanctum/csrf-cookie').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
});


