import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import RichiestaPresaInCaricoList from "./views/RichiestaPresaInCarico/RichiestaPresaInCaricoList";
import RichiestaPresaInCarico from "./views/RichiestaPresaInCarico/RichiestaPresaInCarico";
import RichiestaPresaInCaricoMain from "./views/RichiestaPresaInCarico/RichiestaPresaInCaricoMain";
import store from "./store"

Vue.use(Router)

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true
      },
    },

    {
      path: '/admin/richieste',
      name: 'admin__richieste',
      component: () => import(/* webpackChunkName: "tables" */ './views/Admin/RichiestePresaInCarico')
    },
    {
      path: '/tables',
      name: 'tables',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "tables" */ './views/Tables.vue')
    },
    {
      path: '/forms',
      name: 'forms',
      component: () => import(/* webpackChunkName: "forms" */ './views/Forms.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import(/* webpackChunkName: "profile" */ './views/Profile.vue')
    },

    {
      path: '/richiesta-presa-in-carico',
      component: RichiestaPresaInCaricoMain,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'invia',
          name: 'richiesta-presa-in-carico__invia',
          component: RichiestaPresaInCarico
        },
        {
          path: 'elenco',
          component: RichiestaPresaInCaricoList
        },
        {
          path: ':id',
          name: 'richiesta--presa__single',
          component: () => import(/* webpackChunkName: "single" */ './views/RichiestaPresaInCarico/RichiestaPresaInCaricoSingle.vue')
        },
      ]
    },
    {
      path: '/full-page',
      component: () => import(/* webpackChunkName: "full-page" */ './views/FullPage.vue'),
      children: [

        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Login.vue')
        },
        {
          path: '/account-setup',
          name: 'account-setup',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/AccountSetup.vue')
        },
        {
          path: '/email-ripristino-password',
          name: 'password-recovery-email',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/PasswordRecovery.vue')
        },

        {
          path: '/ripristino-password',
          name: 'set-password',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/SetPassword.vue')
        },
        {
          path: '/error-in-card',
          name: 'error-in-card',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Error.vue')
        },
        {
          path: '/error-simple',
          name: 'error-simple',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Error.vue'),
          props: { isInCard: false }
        },
        {
          path: '/lock-screen',
          name: 'lock-screen',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/LockScreen.vue')
        }
      ]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})



router.beforeEach(async (to, from, next) => {

  // Force scroll to top on every page change to avoid landing mid-page
  window.scrollTo(0,0)

  let requiresAuth = to.matched.some(record => {
    return record.meta.requiresAuth
  })

  if (process.env.NODE_ENV !== 'production') {
    if (to.query.bypass || process.env.VUE_APP_BYPASS_LOGIN) {
      requiresAuth = false
    }
  }
  if (!requiresAuth) {
    next()
    return
  }
  if (!store.state.auth.authenticated) {
    next({
      name: 'login',
      query: {redirect: to.fullPath}
    })
    return
  }

  next()

})

export default router