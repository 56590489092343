<template>
  <div class="trattamenti multi-items form__section">

    <checkbox-picker :options="{disabled:'Non fa trattamenti'}"
                     v-model="disabled" type="is-primary"/>

    <div v-if="!disabled">
      <div :key="index" class="multi-items__row trattamenti__row is-flex" v-for="(trattamento, index) in trattamenti">
        <div class="field is-horizontal">
          <div class="field-label" v-if="trattamento.custom">
            <b-button @click="removeTrattamento(index)" v-if="index" type="is-danger"
                      icon-left="delete">
              Rimuovi
            </b-button>
          </div>
          <div class="field-label">
            <label class="label">Trattamento {{ index + 1 }}</label>
          </div>

          <div class="field-body field-body--wrap">
            <div class="columns">
              <div class="column is-half">
                <BInputValidate :rules="getTipoRules(index)" type="text" label="Tipo" v-model="trattamento.tipo"/>
              </div>
              <div class="column is-half">
                <BInputValidate :rules="getFrequenzaRules(index)" type="text" label="Frequenza"
                                v-model="trattamento.frequenza"/>
              </div>
            </div>
            <div class="columns">
              <div class="column is-half">
                <BInputValidate :rules="getStrutturaRules(index)" type="text" label="Struttura riabilitativa"
                                v-model="trattamento.struttura"/>
              </div>
              <div class="column is-half">
                <BInputValidate :rules="getReferenteRules(index)" type="text" label="Referente"
                                v-model="trattamento.referente"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-field v-if="!disabled" class="trattamenti__cta" label="">
      <div style="text-align:right">
        <b-button
            @click="addTrattamento"
            type="is-info"
            icon-left="plus">
          Aggiungi trattamento
        </b-button>
      </div>
    </b-field>
  </div>
</template>

<script>
import BInputValidate from "../../components/inputs/BInputValidate";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BRadioValidate from "../../components/inputs/BRadioValidate";
import BSelectValidate from "../../components/inputs/BSelectValidate";
import BCheckboxesValidate from "../../components/inputs/BCheckboxesValidate";
import CheckboxPicker from "../../components/CheckboxPicker";

export default {
  name: 'Trattamenti',
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BCheckboxesValidate,
    BSelectValidate,
    BRadioValidate,
    ValidationObserver,
    ValidationProvider,
    BInputValidate,
    CheckboxPicker
  },
  data() {
    return {
      isLoading: false,
      disabled: false,
      trattamenti: [],
      startWith: 4
    }
  },
  methods: {
    getData() {
      if (this.disabled) {
        return [];
      }
      return this.trattamenti.filter(item => {
            return typeof item.tipo !== 'undefined' && item.tipo && item.tipo.length;
          })
          .map(item => {
            return {
              tipo: item.tipo,
              frequenza: item.frequenza,
              struttura: item.struttura,
              referente: item.referente,
            };
          });
    },
    isTrattamentoEmpty(index) {
      const trattamento = this.trattamenti[index]
      return !(trattamento.tipo.length || trattamento.frequenza.length || trattamento.struttura.length || trattamento.referente.length);
    },

    getTipoRules(index) {
      if (this.isTrattamentoEmpty(index)) {
        return '';
      }
      return 'required';
    },

    getFrequenzaRules(index) {
      if (this.isTrattamentoEmpty(index)) {
        return '';
      }
      return 'required';
    },

    getStrutturaRules(index) {
      if (this.isTrattamentoEmpty(index)) {
        return '';
      }
      return 'required';
    },

    getReferenteRules(index) {
      if (this.isTrattamentoEmpty(index)) {
        return '';
      }
      return 'required';
    },
    initTrattamento() {
      return {
        tipo: '',
        frequenza: '',
        struttura: '',
        referente: ''
      }
    },
    addTrattamento() {
      this.trattamenti.push(this.initTrattamento());
    },
    removeTrattamento(indexToRemove) {
      this.trattamenti = this.trattamenti.filter((item, index) => index !== indexToRemove);
    },
    setData(data) {
      if (data.trattamenti && data.trattamenti.length) {
        this.trattamenti = data.trattamenti;
      }
      //this.disabled = data.disabled;
      //this.$forceUpdate();
    }
  },
  mounted() {
    for (let i = 0; i < this.startWith; i++) {
      this.trattamenti.push(this.initTrattamento());
    }
  }
}
</script>

<style scoped lang="scss">
.trattamenti__row + .trattamenti__row, .trattamenti__cta {
  margin-top: 30px;
}

.trattamenti__row > .field > .field-label {
  flex-grow: 1;
  text-align: left;
}

.columns {
  width: 100%;
}
</style>
