const debug = process.env.NODE_ENV !== 'production'

import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import auth from '@/store/auth'


const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
  },
  state: {
    presaInCarico: {},
    loading: false,
    inviteLog: {},
    paziente: {},
  },
  getters: {
    presaInCarico(state) {
      return state.presaInCarico
    },
    loading(state) {
      return state.loading
    },
    inviteLog(state) {
      return state.inviteLog
    },
    paziente(state) {
      return state.paziente
    },
  },
  mutations: {
    SET_PRESA_IN_CARICO(state, presaInCarico) {
      state.presaInCarico = presaInCarico
    },
    SET_LOADING(state, status) {
      state.loading = status
    },

    SET_INVITE_LOG(state, inviteLog) {
      state.inviteLog = inviteLog
    },
    SET_PAZIENTE(state, paziente) {
      state.paziente = paziente
    },

  },
  strict: debug,
  plugins: [vuexLocal.plugin]
})