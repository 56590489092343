import customauth from './custom-auth';
import autologinauth from './autologin-auth';
import axios from '@websanova/vue-auth/drivers/http/axios.1.x';
import router from '@websanova/vue-auth/drivers/router/vue-router.2.x';

// Auth base configuration some of this options
// can be override in method calls
const config = {
  auth: customauth,
  http: axios,
  router: router,
  tokenDefaultName: 'laravel-vue-spa',
  tokenStore: ['cookie'],
  rolesVar: 'role',
  registerData: {url: `${process.env.VUE_APP_API_URL}/register`, method: 'POST', redirect: '/login'},
  loginData: {url: `${process.env.VUE_APP_API_URL}/login`, method: 'POST', redirect: '', fetchUser: true},
  logoutData: {url: `${process.env.VUE_APP_API_URL}/logout`, method: 'POST', redirect: '/', makeRequest: true},
  fetchData: { url: `${process.env.VUE_APP_API_URL}/users`, method: 'GET', enabled: true},
  refreshData: { url: `${process.env.VUE_APP_API_URL}/refresh`, method: 'GET', enabled: true, interval: 30}
}

export default config