 <template>
  <div class="privacy form__section" v-if="ready">
      <h2 class="subtitle">Informativa per l’Utenza</h2>
      <p class="text">Vi informiamo che il Regolamento UE 2016/679 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati, Regolamento Generale sulla Protezione dei Dati (di seguito “Regolamento” o “GDPR” "), prevede la tutela delle persone fisiche rispetto al trattamento dei dati personali. Ai sensi della predetta normativa, il trattamento dei dati personali da noi raccolti sarà improntato ai principi di correttezza, liceità, trasparenza e di tutela della riservatezza e dei diritti dei nostri Utenti e dei loro familiari (Interessati).<strong>La presente costituisce l'informativa resa agli Interessati ai sensi degli articoli 13 e 14 GDPR.</strong>.</p>
      <p class="text">
        <strong>Titolare del trattamento</strong> è la FONDAZIONE ROBERT HOLLMAN – Indirizzo: Via Siena, 1 - 35143 Padova; email: <a href="mailto:privacy@fondazioneroberthollman.it">privacy@fondazioneroberthollman.it</a>.<br>
        Il Titolare ha nominato un Responsabile della protezione dei dati (“DPO”), contattabile ai medesimi recapiti.
      </p>
    <h2 class="subtitle">Fonte dei dati</h2>
    <p class="text">I dati sono forniti dagli Utenti in sede di registrazione sul portale o presso la sede della Fondazione, o sono raccolti tramite soggetti (medico curante o altri soggetti operanti in campo socio sanitario e/o educativo) indicati/autorizzati dagli Utenti.</p>

    <h2 class="subtitle">Dati oggetto del trattamento</h2>
    <p class="text">Raccogliamo DATI COMUNI come: dati anagrafici degli Utenti e dei loro familiari/esercenti la potestà genitoriale (nominativo, luogo e data di nascita, codice fiscale); dati sull’andamento scolastico degli Utenti (documenti e relazioni prodotte dalla scuola); immagini degli Utenti nel loro contesto scolastico o extra scolastico; e DATI PARTICOLARI come: dati sanitari e dati clinici sulla salute degli Utenti (relazioni, esami, referti prodotti dalla Fondazione o da altri soggetti incaricati dalla Fondazione o provenienti dagli Utenti); prestazioni erogate e servizi fruiti (data e tipologia); dati relativi allo stato di salute, dati genetici e dati relativi a patologie degli Utenti e dei loro famigliari.</p>


    <h2 class="subtitle">Finalità del trattamento</h2>
    <p class="text">Utilizziamo i dati personali forniti esclusivamente per le seguenti finalità: attività amministrative interne; pratiche assicurative e legali; invio promemoria appuntamenti e comunicazioni di segreteria; erogazione prestazioni cliniche, diagnostiche e socio-sanitarie; predisposizione invio di relazioni, attestati, documenti, cartelle cliniche; attività progettuali, analisi statistiche, ricerche scientifiche; processi formativi, didattici, di tirocinio e di aggiornamento professionale; comunicazioni non cliniche sulla nostra attività (ad es.: feste, eventi, iniziative, ecc.); gestione immagini foto/video per attività clinica, ricerca e formazione.</p>

    <h2 class="subtitle">Obbligatorietà della comunicazione dei dati</h2>
    <p class="text">Tutti i dati sono trattati esclusivamente a seguito del consenso informato degli Utenti. Tuttavia, la comunicazione dei dati personali è un requisito necessario per poter erogare i servizi richiesti, per cui la mancata comunicazione di tali dati comporta l’impossibilità per il Titolare di procedere in tal senso.</p>

    <h2 class="subtitle">Basi giuridiche per il trattamento:</h2>
    <p class="text">Utilizziamo dati personali facoltativamente, esplicitamente e volontariamente forniti dagli Utenti per richiedere consulenze e accedere ai nostri servizi, pertanto a seguito del rilascio del consenso informato degli Interessati ai sensi del GDPR articolo 6 paragrafo 1 lettera a).</p>

    <h2 class="subtitle">Destinatari</h2>
    <p class="text">I dati personali raccolti non saranno oggetto di diffusione. I dati che La riguardano saranno trattati esclusivamente da personale autorizzato incaricato al trattamento o da Responsabili del trattamento (personale medico, consulenti, collaboratori, società che gestiscono l’infrastruttura IT) designati dal Titolare, il cui elenco è disponibile presso il Titolare.<br>
      I dati non saranno comunicati a terzi, tranne quando sia necessario o previsto dalla legge; potranno essere comunicati inoltre a autonomi titolari, quale medico curante o altri soggetti operanti in campo socio sanitario e/o educativo, che siano di riferimento per gli Utenti; Università e istituti di ricerca con cui sono stati stipulati appositi accordi di analisi statistica, ricerca scientifica, didattica e di formazione.</p>

    <h2 class="subtitle">Modalità di trattamento</h2>
    <p class="text">Il trattamento viene svolto in forma automatizzata e/o manuale, conformemente ai principi di liceità, limitazione delle finalità e minimizzazione dei dati, ex Art 5 GDPR. In ogni caso, i Vostri dati personali non saranno oggetto di trattamenti basati su un processo decisionale automatizzato.</p>

    <h2 class="subtitle">Trasferimento di dati all’estero</h2>
    <p class="text">Per gli Interessati residenti in Italia non è previsto alcun trasferimento di dati fuori dallo Spazio Economico Europeo; per gli Interessati residenti in Svizzera i dati potranno essere trasferiti in Svizzera, con le garanzie previste dalla Decisione di adeguatezza della Commissione UE del 26 luglio 2000 riguardante l'adeguatezza della protezione dei dati personali in Svizzera a norma della direttiva 95/46/CE.</p>

    <h2 class="subtitle">Durata del trattamento</h2>
    <p class="text">I dati personali forniti saranno conservati per il tempo strettamente necessario a conseguire le finalità per cui sono stati raccolti, in ogni caso per un massimo di 10 anni dalla data di cessazione del rapporto di erogazione servizi con la Fondazione; successivamente potranno essere conservati per analisi statistica e di ricerca esclusivamente come dati anonimizzati e/o dati aggregati.<br>
      In sede di cessazione del rapporto con la Fondazione gli Interessati potranno richiedere la conservazione del loro fascicolo (diagnosi, referti e scheda prestazioni), purché in modalità di pseudonimizzazione/criptazione e con adeguate misure di sicurezza, per un periodo di tempo maggiore, a seguito di rilascio di ulteriore e specifico consenso.</p>

    <h2 class="subtitle">Diritti dell’interessato</h2>
    <p class="text">Gli interessati hanno in ogni momento il diritto di chiederci l’accesso ai propri dati personali (art. 15 GDPR); la rettifica dei dati inesatti o l'integrazione di quelli incompleti (art. 16 GDPR); la cancellazione dei dati personali che la riguardano (art. 17 GDPR); la limitazione del trattamento dei dati personali (art. 18 GDPR); la ricezione dei propri dati in un formato strutturato e standard (art. 20 GDPR); l’opposizione al trattamento dei dati personali che li riguardano, salvo che sussistano motivi legittimi per il Titolare di continuare il trattamento (articolo 21, GDPR).<br>
      Nei casi in cui il consenso costituisca la base giuridica per il trattamento di dati personali (art. 6, §. 1, lett. a) e di dati particolari (art. 9, §. 2, lett. a), gli Interessati hanno è il diritto di revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca.<br>
      Se ritenete che i vostri diritti siano stati violati o che abbiamo messo in atto un trattamento scorretto dei vostri dati personali, avete diritto di rivolgervi al Garante della Privacy, che opera in qualità di autorità di controllo per il territorio italiano.</p>

    <h2 class="subtitle">Come potete esercitare i vostri diritti</h2>
    <p class="text">Potete mandare una richiesta scritta alla Fondazione Robert Hollman all’indirizzo postale (Via Siena, 1 - 35143 Padova) o alla casella di posta elettronica privacy@fondazioneroberthollman.it.<br>
      Il Titolare si impegna a rispondere alle richieste entro il termine di un mese, salvo il caso di particolare complessità, per cui potrebbe impiegare sino a un massimo di 3 mesi. In ogni caso, si provvederà a spiegare il motivo dell’attesa entro un mese dalla richiesta.</p>

    <h2 class="subtitle">RILASCIO DI CONSENSO INFORMATO</h2>
    <p class="text text--has-inline-fields">Il sottoscritto <BInputValidate class="field--inline" rules="required" type="text" placeholder="Nome e cognome genitore"  v-model="privacy[0].nomeGenitore"/> </p>
    <p class="text">in qualità di <br> <b-radio-validate :options="{genitore:'Genitore 1 * (specificare sotto)',tutore:'Tutore', esercente:'Esercente la potestà genitoriale (specificare sotto)', altro:'Altro (specificare sotto)', }" rules="required"  v-model="privacy[0].Privacy_sottoscritto_type_1"></b-radio-validate> <br> specifica tipologia <BInputValidate class="field--inline" type="text" placeholder="ad es. Mamma, Papà, etc ..."  v-model="privacy[0].Privacy_sottoscritto_type_text_1"/> <br> del/lla bambino/a  <BInputValidate class="field--inline" rules="required" type="text" placeholder="Nome e cognome bambino"  v-model="privacy[0].nomeBambino"/> </p>

    <p>dopo aver letto e compresa l’Informativa relativa al trattamento dei dati personali resa ai sensi degli articoli 13 e 14 GDPR,</p>

    <b-radio-validate :options="{esprime:'esprime',nega:'nega'}" rules="required|consensoEsprime"  v-model="privacy[0].consenso"></b-radio-validate>
    <p class="text"> il consenso per gestione dei contatti e invio di comunicazioni relative ai servizi</p>
    <hr>

    <b-radio-validate :options="{esprime:'esprime',nega:'nega'}" rules="required|consensoEsprime"  v-model="privacy[1].consenso"></b-radio-validate>
    <p class="text">il consenso per erogazione di prestazioni e servizi socio-sanitari.</p>
    <hr>

    <b-radio-validate :options="{esprime:'esprime',nega:'nega'}" rules="required|consensoEsprime" v-model="privacy[2].migliorareConoscenza"></b-radio-validate>
    <p class="text">il consenso per emissione, su richiesta degli Interessati, di attestati e documenti su servizi resi</p>
    <hr>

    <b-radio-validate :options="{si:'esprime',no:'nega'}" rules="required" v-model="privacy[2].studioRicerca"></b-radio-validate>
    <p class="text">il consenso per attività di analisi statistica, ricerca scientifica, didattica e formativa</p>
    <hr>

    <b-radio-validate :options="{si:'esprime',no:'nega'}" rules="required" v-model="privacy[2].pubblicazioni"></b-radio-validate>
    <p class="text">il consenso per ricezione di comunicazioni sull’attività della Fondazione</p>
    <hr>

    <b-radio-validate :options="{esprime:'presto',nega:'nego'}" rules="required" v-model="privacy[0].Privacy_consenso_foto_generale_1"></b-radio-validate>
    <p class="text">il consenso per la ripresa fotografica e filmata del lavoro svolto nell’ambito delle attività riabilitative del/lla bambino/a presso la Fondazione Robert Hollman.<br>
      Tale materiale fotografico e audiovisivo potrà essere utilizzato esclusivamente dal personale della Fondazione Robert Hollman:<br>
      1. Per migliorare e approfondire la conoscenza delle abilità e acquisizioni del/lla bambina <b-radio-validate class="field--inline" :options="{esprime:'presto',nega:'nego'}" rules="required" v-model="privacy[0].Privacy_consenso_foto_1"></b-radio-validate> il consenso<br>
      2. A scopo di studio e ricerca <b-radio-validate class="field--inline" :options="{esprime:'presto',nega:'nego'}" rules="required" v-model="privacy[0].Privacy_consenso_foto_2"></b-radio-validate> il consenso<br>
      3. Per pubblicazione e presentazioni a convegni <b-radio-validate class="field--inline" :options="{esprime:'presto',nega:'nego'}" rules="required" v-model="privacy[0].Privacy_consenso_foto_3"></b-radio-validate> il consenso</p>
    <hr>

    <p class="text">Si prega di allegare il documento di chi ha compilato il presente modulo.</p>
    <br>
    <ValidationProvider name="Documento Genitore Privacy" rules="filerequired" v-slot="{ errors, valid }">
      <file-picker id="privacy-file" v-model="file" text="Carica un file" label="Documento genitore"></file-picker>
      <p class="help is-danger" v-if="errors.length">{{ errors[0] }}</p>
    </ValidationProvider>
    <br><br>
    <b-field horizontal label="Data di compilazione" style="max-width: 320px;">
      <BInputValidate v-model="dataCompilazione" rules="required" type="text" placeholder="GG/MM/AAAA" v-cleave="cleaveMasks.date" />
    </b-field>

    <p>* Il sottoscritto Genitore 1, consapevole delle conseguenze amministrative e penali per chi rilasci dichiarazioni non corrispondenti a verità, ai sensi del D.P.R. n. 445/2000, dichiara di aver effettuato la registrazione e richiesto la consulenza, anche per l’eventuale Genitore 2, in osservanza delle disposizioni sulla responsabilità genitoriale di cui agli articoli 316, 337ter e 337 quater del codice civile, che richiedono il consenso di entrambi i genitori del minore.<br>
      In ogni caso, Fondazione Robert Hollman si riserva di acquisire il consenso al trattamento dei dati personali e le altre autorizzazioni necessarie agli effetti di legge anche dell’eventuale Genitore 2 prima della proposta di consulenza/trattamento.<br>
      Il sottoscritto Genitore 1, per il caso di sopravvenuta situazione di separazione o divorzio o cessazione di convivenza o scioglimento di unione civile, per cui divenga necessario dimostrare il consenso comune dei genitori, si impegna a far pervenire a Fondazione Robert Hollman il consenso dell’altro genitore, esonerando sin d’ora la medesima Fondazione Robert Hollman da qualsiasi responsabilità derivante dalla mancata comunicazione della modifica della situazione familiare o dalla mancata acquisizione del consenso di entrambi i genitori.<br>
      Il sottoscritto Genitore 1 si impegna a comunicare senza ritardo a Fondazione Robert Hollman ogni modifica e/o aggiornamento dei dati personali, propri e familiari, inseriti al momento della presente registrazione, esonerando sin d’ora la medesima Fondazione Robert Hollman da qualsiasi responsabilità derivante dalla mancata comunicazione di ogni modifica e/o aggiornamento dei dati personali, propri e familiari, o per il caso di modifica e/o aggiornamento dei dati personali.</p>
  </div>
</template>

<script>
  import BInputValidate from "../../components/inputs/BInputValidate";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import BRadioValidate from "../../components/inputs/BRadioValidate";
  import BSelectValidate from "../../components/inputs/BSelectValidate";
  import CheckboxPicker from "../../components/CheckboxPicker";
  import FilePickerDragAndDrop from "../../components/FilePickerDragAndDrop";
  import FilePicker from "../../components/FilePicker";
  import Cleave from "cleave.js";


  const cleave = {
    name: 'cleave',
    bind(el, binding) {
      const input = el.querySelector('input')
      input._vCleave = new Cleave(input, binding.value)
    },
    unbind(el) {
      const input = el.querySelector('input')
      input._vCleave.destroy()
    }
  };

  export default {
    name: 'Privacy',
    directives: {
      cleave
    },
    props: {
      readonly: {
        type: Boolean,
        default: false
      }
    },
    components: {
      BSelectValidate,
      BRadioValidate,
      ValidationObserver,
      ValidationProvider,
      BInputValidate,
      CheckboxPicker,
      FilePicker

    },
    data() {
      return {
        privacy: [],
        ready: false,
        file: '',
        dataCompilazione: '',
        cleaveMasks: {
          date: {
            date: true,
            datePattern: ['d', 'm', 'Y']
          }
        }
      }
    },
    methods: {
      setNomeBambino(nomeBambino){
          this.privacy = this.privacy.map( item => {
              item.nomeBambino = nomeBambino;
              return item;
          });
      },
      initPrivacyItem(tipo)
      {
          return {
            consenso: '',
            nomeGenitore: '',
            nomeBambino: '',
            tipo: tipo,
            migliorareConoscenza: '',
            pubblicazioni: '',
            studioRicerca: '',
          }
      },
      getData() {
        return {
          Privacy_sottoscritto_1: this.privacy[0].nomeGenitore,
          Privacy_sottoscritto_2: this.privacy[1].nomeGenitore,
          Privacy_sottoscritto_3: this.privacy[2].nomeGenitore,
          Privacy_sottoscritto_type_1: this.privacy[0].Privacy_sottoscritto_type_1,
          Privacy_sottoscritto_type_text_1: this.privacy[0].Privacy_sottoscritto_type_text_1,
          Privacy_consenso_foto_1: this.privacy[0].Privacy_consenso_foto_1,
          Privacy_consenso_foto_2: this.privacy[0].Privacy_consenso_foto_2,
          Privacy_consenso_foto_3: this.privacy[0].Privacy_consenso_foto_3,
          Privacy_consenso_foto_generale_1: this.privacy[0].Privacy_consenso_foto_generale_1,
          Privacy_bambino_1: this.privacy[0].nomeBambino,
          Privacy_bambino_2: this.privacy[1].nomeBambino,
          Privacy_bambino_3: this.privacy[2].nomeBambino,
          Privacy_consenso: this.privacy[0].consenso,
          Comunicazioni_consenso: this.privacy[1].consenso,
          Privacy_migliorare_conoscenza: this.privacy[2].migliorareConoscenza,
          Privacy_studio_ricerca: this.privacy[2].studioRicerca,
          Privacy_pubblicazioni: this.privacy[2].pubblicazioni,
          Data_privacy: this.dataCompilazione
        };
      },
      setData(name, surname)
      {
        this.privacy[0].nomeGenitore = name+ " "+surname;
        this.privacy[1].nomeGenitore = name+ " "+surname;
        this.privacy[2].nomeGenitore = name+ " "+surname;
      },
      getFiles(){
        return [{
          path: this.file,
          ente: null,
          luogo: null,
          data: this.$moment().format('DD/MM/YYYY'),
          titolo: "Documento genitore"
        }];
      }
    },
    mounted() {
        this.privacy.push(this.initPrivacyItem('A'));
        this.privacy.push(this.initPrivacyItem('B'));
        this.privacy.push(this.initPrivacyItem('C'));

        this.dataCompilazione = this.$moment().format('DD/MM/YYYY');
        this.ready = true;
    }
  }
</script>

<style scoped lang="scss">
    .subtitle{
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 1.4em;
    }

    .privacy{
      font-size: 14px;
    }

    ul{
      list-style: disc;
      padding-left: 1em;
      padding-top: 5px;
    }

    ol{
      padding-left: 1em;
    }

    .subtitle:not(:first-child){
      margin-top: 20px;
    }

    .field--inline{
      display: inline-block;
      margin: 0 10px;
    }

    p.text--has-inline-fields{
      display: flex;
      align-items: center;
    }

</style>
