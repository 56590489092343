<template>
  <div class="previdenze form__section">

    <checkbox-picker @input="setFieldsAsNo('legge104','legge104_art3')" :options="{legge104disabled:'Non ha la certificazione di handicap (legge 104)'}"
                     v-model="previdenze.legge104disabled" type="is-primary"/>

    <template v-if="!previdenze.legge104disabled">
      <b-field horizontal label="Legge 104 Art.3 comma 1">
        <b-radio-validate @input="toggleMutualExclusive(previdenze.legge104, 'legge104_art3')"  :options="{si:'Sì',no:'No'}" rules="required" v-model="previdenze.legge104"></b-radio-validate>
      </b-field>

      <b-field horizontal label="Legge 104 Art.3 comma 3">
        <b-radio-validate @input="toggleMutualExclusive(previdenze.legge104_art3, 'legge104')" :options="{si:'Sì',no:'No'}" rules="required" v-model="previdenze.legge104_art3"></b-radio-validate>
      </b-field>

      <template v-if="previdenze.legge104 === 'si' || previdenze.legge104_art3 === 'si'">
      <strong>Certificazione di handicap (Legge 104)</strong>
      <div class="files" v-for="(file, index) in getFilesByTitle(previdenze.files, 'Certificazione di handicap (Legge 104)')">
        <div class="columns">
          <div class="column is-one-fifth">
            <b-input-validate v-model="file.data" label="Data" rules="required|date" placeholder="DD/MM/AAAA" v-cleave="cleaveMasks.date"></b-input-validate>
          </div>
          <div class="column is-one-fifth">
            <b-input-validate v-model="file.luogo" label="Luogo" rules="required"></b-input-validate>
          </div>
          <div class="column is-one-fifth" v-show="file.showEnte">
            <b-input-validate v-model="file.ente" label="Ente" rules="required"></b-input-validate>
          </div>
          <div class="column is-one-fifth">
            <ValidationProvider name="Certificazione_handicap" rules="filerequired" v-slot="{ errors, valid }">
              <file-picker id="certificazione_handicap_file" v-model="file.file" text="Carica un file" label="Documento"></file-picker>
              <p class="help is-danger" v-if="errors.length">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>
          <div class="column is-one-fifth">
            <label class="label" style="visibility: hidden">.</label>
            <b-button @click="removeFileById(file.id)" v-if="file.custom" type="is-danger"
                      icon-left="delete">Rimuovi</b-button>
          </div>
        </div>
      </div>
      <b-field class="document__cta" label="">
        <div class="has-text-right">
          <b-button
            @click="addFile(previdenze.files, 'Certificazione di handicap (Legge 104)')"
            type="is-info"
            icon-left="plus">
            Aggiungi documento
          </b-button>
        </div>
      </b-field>
    </template>
    </template>
    <hr>

    <strong>Indennità Civile</strong>
    <br>
    <br>

    <checkbox-picker @input="setFieldsAsNo('indennita_accompagnamento','indennita_frequenza')"
                     :options="{invalidita_disabled:'Non ha la certificazione di invalidità'}"
                     v-model="previdenze.invalidita_disabled" type="is-primary"/>

    <template v-if="!previdenze.invalidita_disabled">
      <b-field horizontal label="Indennità di accompagnamento">
        <b-radio-validate @input="toggleMutualExclusive(previdenze.indennita_accompagnamento, 'indennita_frequenza')" :options="{si:'Sì',no:'No'}" rules="required" v-model="previdenze.indennita_accompagnamento"></b-radio-validate>
      </b-field>

      <b-field horizontal label="Indennità di frequenza">
        <b-radio-validate  @input="toggleMutualExclusive(previdenze.indennita_frequenza, 'indennita_accompagnamento')" :options="{si:'Sì',no:'No'}" rules="required" v-model="previdenze.indennita_frequenza"></b-radio-validate>
      </b-field>

      <template v-if="previdenze.indennita_accompagnamento === 'si' || previdenze.indennita_frequenza === 'si'">
      <div class="files__wrap">
        <strong>Verbale di invalidità civile</strong>
        <div class="files" v-for="(file, index) in getFilesByTitle(previdenze.files, 'Verbale di invalidità civile')">
          <div class="columns">
            <div class="column is-one-fifth">
              <b-input-validate v-model="file.data" label="Data" rules="required|date" placeholder="DD/MM/AAAA" v-cleave="cleaveMasks.date"></b-input-validate>
            </div>
            <div class="column is-one-fifth">
              <b-input-validate v-model="file.luogo" label="Luogo" rules="required"></b-input-validate>
            </div>
            <div class="column is-one-fifth" v-show="file.showEnte">
              <b-input-validate v-model="file.ente" label="Ente" rules="required"></b-input-validate>
            </div>
            <div class="column is-one-fifth">
              <ValidationProvider name="Verbale di invalidità civile" rules="filerequired" v-slot="{ errors, valid }">
                <file-picker v-model="file.file" text="Carica un file" label="Documento"></file-picker>
                <p class="help is-danger" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="column is-one-fifth">
              <label class="label" style="visibility: hidden">.</label>
              <b-button @click="removeFileById(file.id)" v-if="file.custom" type="is-danger"
                        icon-left="delete">Rimuovi</b-button>
            </div>
          </div>
        </div>
        <b-field class="document__cta" label="">
        <div class="has-text-right">
          <b-button
            @click="addFile(previdenze.files, 'Verbale di invalidità civile')"
            type="is-info"
            icon-left="plus">
            Aggiungi documento
          </b-button>
        </div>
      </b-field>
      </div>
    </template>
    </template>
    <hr>

    <checkbox-picker @input="setFieldsAsNo('indennita_ciecoassoluto','indennita_ciecoventesimista')"
            :options="{invalidita_sensoriali_disabled:'Non ha la certificazione di invalidità per minorazioni sensoriali'}"
                     v-model="previdenze.invalidita_sensoriali_disabled" type="is-primary"/>

    <template v-if="!previdenze.invalidita_sensoriali_disabled">
      <b-field horizontal label="Indennità di cieco assoluto">
        <b-radio-validate @input="toggleMutualExclusive(previdenze.indennita_ciecoassoluto, 'indennita_ciecoventesimista')" :options="{si:'Sì',no:'No'}" rules="required" v-model="previdenze.indennita_ciecoassoluto"></b-radio-validate>
      </b-field>

      <b-field horizontal label="Indennità di cieco ventesimista (cecità parziale)">
        <b-radio-validate @input="toggleMutualExclusive(previdenze.indennita_ciecoventesimista, 'indennita_ciecoassoluto')" :options="{si:'Sì',no:'No'}" rules="required" v-model="previdenze.indennita_ciecoventesimista"></b-radio-validate>
      </b-field>

      <template v-if="previdenze.indennita_ciecoassoluto === 'si' || previdenze.indennita_ciecoventesimista === 'si'">
        <div class="files__wrap">
          <strong>Verbale di invalidità per minorazioni sensoriali</strong>
          <div :key="index" class="files" v-for="(file, index) in getFilesByTitle(previdenze.files, 'Verbale di invalidità per minorazioni sensoriali')">
            <div class="columns">
              <div class="column is-one-fifth">
                <b-input-validate v-model="file.data" label="Data" rules="required|date" placeholder="DD/MM/AAAA" v-cleave="cleaveMasks.date"></b-input-validate>
              </div>
              <div class="column is-one-fifth">
                <b-input-validate v-model="file.luogo" label="Luogo" rules="required"></b-input-validate>
              </div>
              <div class="column is-one-fifth" v-show="file.showEnte">
                <b-input-validate v-model="file.ente" label="Ente" rules="required"></b-input-validate>
              </div>
              <div class="column is-one-fifth" >
                <ValidationProvider name="Verbale di invalidita" rules="filerequired" v-slot="{ errors, valid }">
                  <file-picker id="minorazioni_sensoriali_file" v-model="file.file" text="Carica un file" label="Documento"></file-picker>
                  <p class="help is-danger" v-if="errors.length">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
              <div class="column is-one-fifth">
                <label class="label" style="visibility: hidden">.</label>
                <b-button @click="removeFileById(file.id)" v-if="file.custom" type="is-danger"
                          icon-left="delete">Rimuovi</b-button>
              </div>
            </div>
          </div>
          <b-field class="document__cta" label="">
            <div class="has-text-right">
              <b-button
                      @click="addFile(previdenze.files, 'Verbale di invalidità per minorazioni sensoriali')"
                      type="is-info"
                      icon-left="plus">
                Aggiungi documento
              </b-button>
            </div>
          </b-field>
        </div>

      </template>
    </template>



    <hr>

    <b-field horizontal label="Malattia rara">
      <b-radio-validate :options="{si:'Sì',no:'No'}" rules="required" v-model="previdenze.malattiarara.value"></b-radio-validate>
    </b-field>

    <template v-if="previdenze.malattiarara.value === 'si'">
      <div class="files__wrap">
        <strong>Certificazione malattia rara</strong>
        <div class="files" v-for="(file, index) in getFilesByTitle(previdenze.files, 'Certificazione malattia rara')">
          <div class="columns">
            <div class="column is-one-fifth">
              <b-input-validate v-model="file.data" label="Data" rules="required|date" placeholder="DD/MM/AAAA" v-cleave="cleaveMasks.date"></b-input-validate>
            </div>
            <div class="column is-one-fifth">
              <b-input-validate v-model="file.luogo" label="Luogo" rules="required"></b-input-validate>
            </div>
            <div class="column is-one-fifth" v-show="file.showEnte">
              <b-input-validate v-model="file.ente" label="Ente" rules="required"></b-input-validate>
            </div>
            <div class="column is-one-fifth">
              <ValidationProvider name="Certificazione malattia rara" rules="filerequired" v-slot="{ errors, valid }">
                <file-picker v-model="file.file" text="Carica un file" label="Documento"></file-picker>
                <p class="help is-danger" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="column is-one-fifth">
              <label class="label" style="visibility: hidden">.</label>
              <b-button @click="removeFileById(file.id)" v-if="file.custom" type="is-danger"
                        icon-left="delete">Rimuovi</b-button>
            </div>
          </div>
        </div>
        <b-field class="document__cta" label="">
          <div class="has-text-right">
            <b-button
              @click="addFile(previdenze.files, 'Certificazione malattia rara')"
              type="is-info"
              icon-left="plus">
              Aggiungi documento
            </b-button>
          </div>
        </b-field>
      </div>

      <b-field horizontal label="Codice di esenzione">
        <BInputValidate rules="required" type="text" v-model="previdenze.codice_esenzione"/>
      </b-field>


      <b-field horizontal label="Codice ICD-9-CM">
        <BInputValidate rules="required" type="text" v-model="previdenze.codice_icd"/>
      </b-field>
    </template>

    <hr>
  </div>
</template>

<script>
  import BInputValidate from "../../components/inputs/BInputValidate";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import BRadioValidate from "../../components/inputs/BRadioValidate";
  import BSelectValidate from "../../components/inputs/BSelectValidate";
  import CheckboxPicker from "../../components/CheckboxPicker";
  import FilePickerDragAndDrop from "../../components/FilePickerDragAndDrop";
  import FilePicker from "../../components/FilePicker";
  import Cleave from "cleave.js";


  const cleave = {
    name: 'cleave',
    bind(el, binding) {
      const input = el.querySelector('input')
      input._vCleave = new Cleave(input, binding.value)
    },
    unbind(el) {
      const input = el.querySelector('input')
      input._vCleave.destroy()
    }
  };

  export default {
    name: 'Previdenze',
    directives: {
      cleave
    },
    props: {
      readonly: {
        type: Boolean,
        default: false
      }
    },
    components: {
      FilePicker,
      BSelectValidate,
      BRadioValidate,
      ValidationObserver,
      ValidationProvider,
      BInputValidate,
      CheckboxPicker,
      FilePickerDragAndDrop

    },
    data() {
      return {
        cleaveMasks: {
          date: {
            date: true,
            datePattern: ['d', 'm', 'Y']
          }
        },
        isLoading: false,
        previdenze: {
          legge104disabled: false,
          invalidita_disabled: false,
          invalidita_sensoriali_disabled: false,
          legge104: null,
          legge104_art3: null,
          indennita_accompagnamento: null,
          indennita_frequenza: null,
          files: [],
          malattiarara: {
            value: null
          },
          indennita_ciecoassoluto: null,
          indennita_ciecoventesimista: null,
          codice_esenzione: '',
          codice_icd: ''
        }
      }
    },
    methods: {
      toggleMutualExclusive(field1, field2Name){
          if(field1 === "si"){
            this.previdenze[field2Name] = "no";
            return;
          }
        this.previdenze[field2Name] = "si";
      },
      getAusiliiVisiviRules(){
        if(this.ausilii.motori.length || this.ausilii.acustici.length){
          return '';
        }
        return 'required';
      },

      getAusiliiMotoriRules(){
        if(this.ausilii.visivi.length || this.ausilii.acustici.length){
          return '';
        }
        return 'required';
      },

      setFieldsAsNo(first, second)
      {
          this.previdenze[first] = 'no';
          this.previdenze[second] = 'no';
      },

      getAusiliiAcusticiRules(){
        if(this.ausilii.motori.length || this.ausilii.visivi.length){
          return '';
        }
        return 'required';
      },
      initFile(title, custom = true, ente = ''){
          let file = {
            id: this.previdenze.files.length,
            file: null,
            luogo: '',
            ente: ente,
            data: null,
            custom: custom,
            titolo: title
          };
          file.showEnte = !(ente.length);
          return file;
      },
      addFile(collection, title, custom = true, ente = ''){
          collection.push(this.initFile(title, custom, ente));
      },
      removeFileById(idToRemove) {
        this.previdenze.files = this.previdenze.files.filter((item, index) => item.id !== idToRemove);
      },
      removeFile(collection, title, indexToRemove) {
        let c = 0;
        collection = collection.filter(item => {
            if(item.titolo !== title){
              return true;
            }
            if(c === indexToRemove){
              return false;
            }
            c++;
            return true;
          })
        this.previdenze.files = collection;
      },
      getFilesByTitle(collection, title){
        return this.previdenze.files.filter(item => {
          return item.titolo === title;
        });
      },
      getData(){
        return {
          Legge_104: this.previdenze.legge104,
          Legge_104_art_3_comma_3: this.previdenze.legge104_art3,
          Indennita_di_accompagnamento: this.previdenze.indennita_accompagnamento,
          Indennita_di_frequenza: this.previdenze.indennita_frequenza,
          Indennita_di_cieco_assoluto: this.previdenze.indennita_ciecoassoluto,
          Indennita_di_cieco_ventesimista: this.previdenze.indennita_ciecoventesimista,
          Malattia_rara: this.previdenze.malattiarara.value,
          Codice_di_esenzione: this.previdenze.codice_esenzione,
          Codice_icd9m: this.previdenze.codice_icd
        }
      },
      setData(data)
      {
        this.previdenze = {
          ...this.previdenze,
          ...data
        };
      },
      getFiles(){
        return this.previdenze.files.filter(item => {
          return typeof item.file !== 'undefined' && item.file && item.file.length;
        }).map(item => {
          return {
            path: item.file,
            ente: item.ente,
            luogo: item.luogo,
            data: item.data,
            titolo: item.titolo
          }
        })
      }
    },
    mounted(){
        this.addFile(this.previdenze.files, 'Certificazione malattia rara', false);
        this.addFile(this.previdenze.files, 'Certificazione di handicap (Legge 104)', false, 'INPS');
        this.addFile(this.previdenze.files, 'Verbale di invalidità per minorazioni sensoriali', false, 'INPS');
        this.addFile(this.previdenze.files, 'Verbale di invalidità civile', false, 'INPS');
    }
  }
</script>

<style scoped lang="scss">
  .is-fullwidth{
    width: 100%;
  }
  .previdenze__row{
    margin: 30px 0;
    .field-body{
      .is-fullwidth.is-block{
        margin-bottom: 5px;
      }
    }
  }
  .ausilii__row{
    margin: 30px 0;
  }
  .field.document__cta {
    margin-top: 15px;
  }
</style>
